<template>
  <div>
    <div v-if="desktop" class="no-results desktop">
      <NoResultsGraphicText />
      <p class="description">
        We couldn't find any photos for <strong>{{ query }}</strong
        >. Try another search term or
        <a href="http://hgtv.custhelp.com/">contact us</a> if you've reached
        this page in error.
      </p>
      <NoResultsGraphic />
    </div>
    <div v-else class="no-results mobile">
      <NoResultsGraphic />
      <NoResultsGraphicText />
      <p class="description">
        We couldn't find any photos for <strong>{{ query }}</strong
        >. Try another search term or
        <a href="http://hgtv.custhelp.com/">contact us</a> if you've reached
        this page in error.
      </p>
    </div>
  </div>
</template>

<script>
import NoResultsGraphic from './assets/no-results-graphic.svg';
import NoResultsGraphicText from './assets/no-results-graphic-text.svg';

export default {
  name: 'NoResults',

  components: {
    NoResultsGraphic,
    NoResultsGraphicText,
  },

  props: {
    query: {
      type: String,
      default: '',
    },

    desktop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 10px 60px;

  #No_Results_Graphic,
  #no-results-graphic_svg__No_Results_Graphic {
    height: 190px;
    margin: 20px auto;

    .st0 {
      fill: #f7f7f7;
    }
    .st1 {
      fill: #c2eaf2;
    }
    .st2 {
      fill: #202429;
    }
    .st3 {
      fill: #09adc3;
      enable-background: new;
      opacity: 0.1865;
    }
  }

  #Script-Text-Hmmm,
  #no-results-graphic-text_svg__Script-Text-Hmmm {
    height: 27px;
    margin: 15px 0;
    align-self: flex-start;

    .st0 {
      fill: #202429;
    }
  }

  .description {
    font-size: 15px;

    a {
      color: #09adc3;
      text-decoration: none;
    }
  }

  &.desktop {
    justify-content: center;
    align-items: center;

    #no-results-graphic-text_svg__Script-Text-Hmmm {
      margin: 15px auto;
    }

    .description {
      text-align: center;
      width: 512px;
    }
  }
}
</style>
