import { getField, updateField } from 'vuex-map-fields';

export const state = () => {
  return {
    list: [],
    map: {},
    selected: [],
    searchTerm: '',
  };
};

const clearSelected = (state, facet = null) => {
  if (facet) {
    let selectedIndex = state.selected.findIndex(f => f.index === facet.index);
    if (selectedIndex >= 0) {
      state.selected.splice(selectedIndex, 1);
    }
  } else {
    state.selected = [];
  }
};

export const mutations = {
  updateField,
  setList(state, facetList) {
    state.list = facetList;
  },
  mapFacets(state) {
    let map = {
      ...state.map,
    };
    state.list.forEach(facet => {
      facet.info = getFacetInfo(state, facet);
      map = setFacet(map, facet);
    });
    state.map = map;
  },
  clearSelected,
  addSelected(state, index) {
    let facet = state.list.find(facet => facet.index == index);
    clearSelected(state, facet);
    if (facet && facet.info && facet.info.parent) {
      clearSelected(state, facet.info.parent);
    }
    state.selected.push(facet);
  },
};

export const getters = {
  getField,
  isSearch(state) {
    return state.searchTerm && state.searchTerm.length > 0;
  },
  facetRoute(state) {
    const path = state.selected
      .map(facet => facet.info.slug)
      .sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    let route = (path && path.length && path.join('/')) || '';
    if (route) {
      route = `rooms/viewer/${route}`;
    }
    return route;
  },
};

const getFacet = (state, index) => {
  return state.list.find(facet => facet.index === index);
};

const getFacetByIndex = ({ state }, index) => {
  return getFacet(state, index);
};

const getLabel = facet => {
  const val = facet.value;
  const segment = val.split('#').pop();
  const segments = segment.split(':');
  const path = segments.pop().split('/');
  const lastIndex = path.length - 1;

  return path[lastIndex];
};

const getFacetUrlLabel = ({ state }, { index }) => {
  const facet = getFacetByIndex({ state }, index);
  if (facet) {
    let label = getLabel(facet);
    return label
      .toLowerCase()
      .split('_')
      .join('-');
  }
  return null;
};

const getFacetLabel = ({ state }, { index }) => {
  const label = getFacetUrlLabel({ state }, { index });
  return label.split('_').join(' ');
};

export const actions = {
  addFacets({ commit }, { facets }) {
    if (facets) {
      commit('setList', facets);
      commit('mapFacets');
    } else {
      console.warn(
        'ERROR in store.modules.facets: no data passed to addFacets()',
      );
    }
  },
  selectFacetByValue({ commit, state }, value) {
    let name = value.split('/').pop();
    let facet = getFacetByName(state, name);
    if (facet) {
      commit('addSelected', facet.index);
    }
  },
  selectFacetByName({ commit, state }, name) {
    let facet = getFacetByName(state, name);
    if (facet) {
      commit('addSelected', facet.index);
    }
  },
  selectFacetBySlug({ commit, state }, slug) {
    let facet = getFacetBySlug(state, slug);
    if (facet) {
      commit('addSelected', facet.index);
    }
  },
  selectFacetByIndex({ commit }, index) {
    commit('addSelected', index);
  },
  getFacetByIndex,
  getFacetUrlLabel,
  getFacetLabel,
};

const isRoom = facet => {
  return facet.value.indexOf('ROOMS_AND_SPACES_SFACET') === 0;
};

const isColor = facet => {
  return facet.value.indexOf('COLOR_SFACET') === 0;
};

const isStyle = facet => {
  return facet.value.indexOf('STYLE_SFACET') === 0;
};

const getFacetByName = (state, name) => {
  name = name
    .split(' ')
    .join('_')
    .toLowerCase();
  let facet = state.list.find(f => {
    let category = f.value
      .toLowerCase()
      .split('#')
      .pop();
    let path = category.split(':').pop();
    let segment = path.split('/').pop();
    let isTrue = segment.indexOf(name) === 0;
    return isTrue;
  });
  return facet || null;
};

const getFacetBySlug = (state, slug) => {
  slug = slug.toLowerCase();
  let facet = state.list.find(f => f.info.slug === slug);
  return facet || null;
};

// get the hierarchy level of the facet
const getFacetInfo = (state, facet) => {
  // ROOMS_AND_SPACES_SFACET:2/other_space/hall#rooms_and_spaces:other_space/hall
  if (!facet) return -1;
  const categoryInfo = facet.value.split('#').shift();
  let slug = getFacetUrlLabel({ state }, { index: facet.index });
  let label = getFacetLabel({ state }, { index: facet.index });
  if (categoryInfo.indexOf('/') < 0) {
    slug = slug === 'shoppable' ? 'shop' : slug;
    return {
      level: 1,
      path: categoryInfo
        .split(':')
        .pop()
        .toLowerCase(),
      parent: null,
      slug,
      label,
    };
  }
  const topLevelInfo = categoryInfo
    .split(':')
    .pop()
    .split('/');
  const level = parseInt(topLevelInfo.shift());
  const path = topLevelInfo.join('/');
  const parentName = level > 1 ? topLevelInfo[topLevelInfo.length - 2] : '';
  const parent = getFacetByName(state, parentName);

  return {
    level,
    path,
    parent,
    slug,
    label,
  };
};

const setFacet = (map, facet) => {
  let val = facet.value;
  let segment = val.split('#').pop();
  let segments = segment.split(':');
  let facetType = isRoom(facet)
    ? 'rooms'
    : isColor(facet)
    ? 'color'
    : isStyle(facet)
    ? 'style'
    : 'custom';
  let path = segments.pop().split('/');
  let lastIndex = path.length - 1;

  map[facetType] = map[facetType] || {};
  path.reduce((o, p, i) => {
    o[p] = o[p] || {};
    if (i === lastIndex) {
      o[p].index = facet.index;
    }
    return o[p];
  }, map[facetType]);

  return map;
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
