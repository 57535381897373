<template>
  <row
    :is-split="display.isSplit"
    :is-reversed="display.isReversed"
    :has-gallery="display.hasGallery"
    :index="index"
    :route="route"
  >
    <FeedPhoto
      :container-attrs="
        allGalleries ? galleryContainerAttrs : mainPhotoContainerAttrs
      "
      :image="photo"
      :lazyload="allGalleries ? false : lazyload"
      :data-track="photo.track"
      :data-mdm="photo.mdm"
      :hideTitle="allGalleries"
      :cta="allGalleries ? 'View Collection' : null"
      :photo-wrapper-class="{ 'c-Feed--photo-wrapper': true }"
      :card-image-class="{ [cardImageClass]: true }"
      css-class="c-Feed--photo"
    />
    <div class="c-Feed--group">
      <FeedPhoto
        :container-attrs="galleryContainerAttrs"
        :image="gallery"
        :lazyload="false"
        :data-track="gallery.track"
        :data-mdm="gallery.mdm"
        :photo-wrapper-class="{ 'c-Feed--photo-wrapper': true }"
        :cta="'View Collection'"
        :card-image-class="{ [cardImageClass]: true }"
        css-class="c-Feed--photo"
      />
    </div>
  </row>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import row from '@common/row';
import feedRowMixin from '@mixins/feed-row';
import FeedPhoto from '@common/feed-photo';

export default {
  name: 'Gallery',
  components: {
    row,
    FeedPhoto,
  },
  mixins: [feedRowMixin],
  props: {
    rowType: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    display: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    nextGalleryImage(index) {
      let imgs = this.images.filter(image => image.type === 'gallery');
      if (imgs.length) {
        return imgs[index];
      }
    },
  },
  computed: {
    allGalleries() {
      let imgs = this.images.filter(image => image.type === 'gallery');
      return imgs.length > 0 && imgs.length === this.images.length;
    },
    photo() {
      let img = this.images.filter(image => image.type === 'photo')[0];
      if (typeof img === 'undefined' && this.allGalleries) {
        return this.gallerySecondary;
      }
      return img;
    },
    gallery() {
      return this.nextGalleryImage(0);
    },
    gallerySecondary() {
      return this.nextGalleryImage(1);
    },
    galleryContainerAttrs() {
      let attrs = cloneDeep(this.photoContainerAttrs);
      attrs.class['c-Feed--gallery'] = true;
      return attrs;
    },
    mainPhotoContainerAttrs() {
      let attrs = cloneDeep(this.photoContainerAttrs);
      attrs.class[this.cardImageClass] = true;
      attrs.class['c-Feed--item--photo'] = true;
      return attrs;
    },
    route() {
      let img = this.images.find(image => image.route);
      return (img && img.route) || {};
    },
  },
};
</script>
