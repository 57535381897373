export default {
  namespaced: true,

  state: () => {
    return {
      active: null,
      images: [],
      lightboxId: null,
      hasLightbox: true,
    };
  },

  getters: {
    hasActiveImage(state) {
      let a = state.active;
      return typeof a !== 'undefined' && a != null;
    },

    lightboxEnabled(state) {
      return state.hasLightbox;
    },

    first(state) {
      return state.images[0];
    },

    last(state) {
      return state.images[state.images.length - 1];
    },

    imgCount(state) {
      return state.images.length;
    },
  },

  mutations: {
    setActive(state, image = null) {
      state.active = image;
    },

    setHasLightbox(state, val) {
      state.hasLightbox = val;
    },

    addImage(state, { image, getters: { last } }) {
      last && last.index !== null && last.index > image.index
        ? state.images.push(image, state.images.pop()) // sorting in place
        : state.images.push(image);
    },

    resetImages(state) {
      state.images = [];
    },
  },

  actions: {
    setLightbox({ commit }, val) {
      commit('setHasLightbox', val);
    },

    addImage({ commit, getters }, image) {
      commit('addImage', { image, getters });
    },

    setActiveImage({ commit }, image) {
      commit('setActive', image);
    },

    setNextImage({ commit, state, getters }, direction = 1) {
      const { imgCount } = getters;

      // only do this if the images array has items
      if (imgCount) {
        let idx = state.images.findIndex(el => el === state.active);

        let nextIdx = idx + direction;

        if (nextIdx >= imgCount) {
          nextIdx = imgCount - 1;
        }

        if (nextIdx < 0) {
          nextIdx = 0;
        }

        let nextActive = state.images[nextIdx];

        commit('setActive', nextActive);
      }
    },
    resetLightbox({ commit }) {
      commit('resetImages');
    },
  },
};
