<template>
  <section
    v-observe-visibility="{
      callback: visibilityChanged,
    }"
    :class="displayOptions"
    class="c-Feed--row"
    data-track="true"
    data-row="true"
    :data-index="index"
    :data-term="route.term"
    :data-dimensions="route.dimensions"
    :data-rowIndex="index"
    :data-page="route.page"
    :data-photoId="photoId"
  >
    <slot />
  </section>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
export default {
  name: 'Row',
  props: {
    isSplit: {
      type: Boolean,
      required: true,
    },
    isReversed: {
      type: Boolean,
      required: true,
    },
    hasGallery: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: 0,
    },
    route: {
      type: Object,
      default: () => {},
    },
    photoId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayOptions: {
        'l-Split': this.isSplit,
        'l-Reverse': this.isReversed,
        'has-Gallery': this.hasGallery,
      },
    };
  },
  computed: {
    ...mapFields('feed', ['offset']),
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        const { baseURI, dataset } = entry.target;
        const rowIndex = parseInt(dataset.index);
        if (rowIndex >= 0) {
          this.updateRoute({
            baseURI,
            ...dataset,
            rowIndex,
          });
        }
      }
    },
    getRoute({ term, dimensions, page, rowIndex, lightboxId }) {
      console.log('>> rowIndex', rowIndex);

      if (!window.scrollY) {
        rowIndex = 0;
      }

      if (term === 'undefined') {
        term = '';
      }
      let path = '/';
      if (term) {
        path = `${path}term/${term}/`;
      }
      if (dimensions) {
        path = `${path}${dimensions}/`;
      }
      if (typeof page === 'undefined') {
        page = this.offset;
      }
      if (page > 0) {
        path = `${path}page/${page}/`;
      }
      if (rowIndex > 0) {
        path = `${path}row/${rowIndex}/`;
      }
      if (lightboxId) {
        path = `${path}lightbox/${lightboxId}`;
      }
      return path;
    },
    updateRoute(routeData) {
      let vm = this;
      let path = vm.getRoute(routeData);
      if (vm.$route.path !== path) {
        vm.$router.push({ path }).catch(err => {
          console.error(err);
        });
      }
    },
  },
};
</script>
