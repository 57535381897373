/* globals SniAds, s, SNI, mdManager, moduleTrack */

import 'intersection-observer';
import 'whatwg-fetch';
import 'element-closest';
import 'url-search-params-polyfill';
import 'url-polyfill';
import loader from '@components/common/loader';
import noResults from '@components/common/no-results';
import anchorFilter from '@components/common/anchor-filter';
import { mapActions, mapState, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import globals from './globals';
import analytics from './analytics';
import { events } from '@constants/dfptype.js';

if (globals.hasWindow && typeof SNI === 'undefined') {
  window.SNI = {};
}

function checkDefined($context, $variable, $callback) {
  if ($context[$variable]) {
    $callback();
  } else {
    Object.defineProperty($context, $variable, {
      configurable: true,
      enumerable: true,
      writeable: true,
      get: function() {
        return $context['_' + $variable];
      },
      set: function(val) {
        $context['_' + $variable] = val;
        $callback();
      },
    });
  }
}

export default {
  name: 'App',

  components: {
    loader,
    noResults,
    anchorFilter,
  },
  mixins: [globals, analytics],

  provide() {
    return {
      getMDM: this.getMDM,
      trackProductImpression: this.trackProductImpression,
    };
  },

  data() {
    return {
      bottom: false,
      sticky: false,
      trackShopping: false,
      ads: false,
      adInjectionTarget: false,
      io: false,
      isSearch: false,
      selectedSearchTerm: '',
      searchVisible: false,
      allowDynamicPageview: false,
      contextualTitle: false,
      watchSearchTermAndFacets: true,
      observerOptions: {
        threshold: 0.1,
      },
      brandPath: 'hgtv-photos',
      vendor: `/content/products/vendor-api.json/vendor1`,
      navbar: 'navbar-brand',
      cardSel: 'card',
      imageSel: '[data-track="true"]',
      adSel: 'data-feed-ad',
      location: 'home',
      searchAction: '',
      direction: '',
      scrollPos: 0,
      threshold: 0,
      subsetLength: 6,
      adCount: 1,
      ninetyPercentWindowHeight: 0,
      pageviewThreshold: 0,
      pageviewCounter: 0,
      facetChange: 0,
      rooms: [],
      styles: [],
      colors: [],
      children: [],
      products: [],
      isLoading: false,
      noResults: false,
      anchorFilterOptions: {},
      anchorFilterWidth: 0,
      productsReady: false,
      firstLoad: true,
      hasContextualTitle: false,
      defaultTitle: 'Photo Inspiration',
      initialized: false,
      initialPath: '',
      initialRoute: '',
      initialPage: null,
      routerWatchFacets: false, // Wait until route init is done to allow facets watching
      isLandingPage: false,
      routeCheckDone: false, // used to set lightbox image on page load only
      appReadyForScroll: false, // when visibility changes, this flag will be set
      foundLBImage: false, // the image reference for the image we scroll to
      scrollToImage: '', // the image.id of the image to scroll to
      pageNum: 0,
      totalPages: 1,
      lastViewedPage: 1,
      pageUrl: '',
      hidePagination: false,
      hashProps: {},
    };
  },
  computed: {
    // mapFields provides simple 2-way data bind for Vuex fields in
    // components:  https://github.com/maoberlehner/vuex-map-fields
    ...mapFields('feed', ['offset', 'numOfResults', 'facets', 'searchTerm']),
    ...mapState('feed', ['images', 'renditionSize', 'rows', 'imagesByRow']),
    ...mapState(['route']),
    ...mapState('facets', {
      facetList: 'list',
      selected: 'selected',
      tempMap: 'list',
    }),
    ...mapState('labs', ['experiments']),
    ...mapFields(['routeInfo']),
    ...mapGetters('feed', ['getFacetCategory', 'getFacetCategoryAndValue']),
    ...mapGetters('facets', ['facetRoute']),
    facetEndpoint() {
      return `/content/${this.brandPath}/inspiration-feed.json`;
    },
    searchEndpoint() {
      return `/content/${this.brandPath}/inspiration-feed-search.json`;
    },
    availableFilters() {
      if (this[this.location]) {
        return this[this.location];
      }
      return false;
    },
    deflatedFacets() {
      return this.facets.map(facet => this.deflateFacet(facet));
    },
    selectedCategories() {
      return this.facets.map(facet => this.getFacetCategory(facet));
    },
    productIds() {
      return [
        ...new Set(
          this.images
            .filter(image => image.shoppable)
            .map(image => image.productId)
            .filter(id => id),
        ),
      ].filter(id => !this.products.map(product => product.id).includes(id));
    },
    changedFacets() {
      return JSON.parse(JSON.stringify(this.facets));
    },
    searchMetadata() {
      const exp = this.experiments.find(exp => exp.isActive);
      const treatment = exp && exp.treatments.find(t => t.isActive);
      const abcellValue = treatment && `${exp.id}_${treatment.id}`;
      return {
        searchTerms: this.searchTerm,
        keyterm: this.searchTerm,
        dimensions: this.facets
          .map(facet => this.getFacetCategory(facet, true))
          .join(','),
        dimensionValues: this.facets
          .map(facet => this.getFacetCategoryAndValue(facet))
          .join(','),
        noSearchResults:
          this.searchTerm && this.noResults
            ? `no results:${this.searchTerm}`
            : '',
        filter: this.searchAction,
        internalSearchType: 'photo search',
        abcell: abcellValue,
      };
    },
    navbarBrandPaddingLeft() {
      return this.anchorFilterWidth
        ? this.anchorFilterWidth + 20
        : this.anchorFilterWidth;
    },
  },
  watch: {
    // The value changes when visibility changes for the app
    appReadyForScroll(ready) {
      if (ready) {
        console.log('Scroll to image');
      }
    },
    bottom(bottom) {
      if (bottom && this.images.length > 0 && this.pageNum < this.totalPages) {
        let showIsLoading = false;
        this.pageNum = this.pageNum + 1;
        this.addImages(showIsLoading);
      }
    },
    sticky(sticky) {
      if (!sticky) {
        this.searchVisible = false;
      }
    },
    // Can receive val and old
    facets() {
      // const absScrollPos = Math.abs(this.scrollPos);
      // let length = (facetsVal && facetsVal.length) || 0;
      // return;
      // if (length === 0) return;
      // if (length && this.watchSearchTermAndFacets) {
      //   // this is a hack put in place because something is causing an extra facet change
      //   // we should reset this to 1 when we fix that
      //   let changeThreshold = 1;
      //   this.setThreshold();
      //   this.removeAds();
      //   if (!absScrollPos) {
      //     this.pageviewCounter = 0;
      //     this.pageviewThreshold = this.ninetyPercentWindowHeight;
      //   }
      //   this.facetChange++;
      //   if (this.facetChange > changeThreshold && !this.selectedSearchTerm) {
      //     this.contextualTitle = false;
      //   }
      // this.setRouteInfo(); If nothing changed, why call this?
      // }
    },
    changedFacets(current, previous) {
      let facetsChanged = false;
      if (current && previous) {
        if (current.length < previous.length) {
          // removed - what's in previous that wasn't in current?
          this.searchAction = previous
            .filter(facet => !current.includes(facet))
            .map(facet => `remove:${this.getFacetCategoryAndValue(facet)}`)
            .join(',');
          facetsChanged = true;
        } else {
          // added - what's in current that wasn't in previous?
          if (current.length > 0 || previous.length > 0) {
            this.searchAction = current
              .filter(facet => !previous.includes(facet))
              .map(
                facet => `refinement:${this.getFacetCategoryAndValue(facet)}`,
              )
              .join(',');
            facetsChanged = true;
          }
        }
      }
      if (!this.routerWatchFacets) return;
      if (facetsChanged) this.setRouteInfo();
    },
    searchTerm(searchTerm) {
      if (this.watchSearchTermAndFacets) {
        if (searchTerm === 'undefined') {
          this.searchTerm = '';
        } else if (searchTerm) {
          this.searchAction = `search:${this.searchTerm}`;
        } else {
          this.searchAction = '';
        }
      }
    },
    offset(offset) {
      const url = this.fetchBaseUrl();
      this.fetchPageUrl(url);
      if (offset) {
        if (!this.allowDynamicPageview) this.allowDynamicPageview = true;
        if (offset >= this.numOfResults * 2) {
          this.searchAction = `pagination:${this.offset / this.numOfResults}`;
        }
      }
    },
    location(loc) {
      if (loc) {
        this.children = [];
      }
    },
    availableFilters(availableFilters) {
      if (availableFilters) {
        const parent = this.children.filter(child => !child.parent)[0];
        if (parent) {
          this.children = this.availableFilters.filter(
            filter => filter.Id == parent.Id,
          )[0].children;
        }
      }
    },
    route(to, from) {
      let tp = to.params;
      let fp = from.params;
      let fromTerm = decodeURIComponent(fp.term || '');
      let toTerm = decodeURIComponent(tp.term || '');
      let fromDim = fp.dimensions || '';
      let toDim = tp.dimensions || '';
      let hasSearchChanged = fromTerm !== toTerm;
      let haveFacetsChanged = fromDim !== toDim;
      let isModifiedSearchChange = hasSearchChanged && toTerm !== '';

      if (
        isModifiedSearchChange &&
        haveFacetsChanged &&
        typeof toDim !== 'undefined'
      ) {
        // set flag to disable facet & search watchers
        this.watchSearchTermAndFacets = false;
        // update route to include search term & shoppable dimension if present (getRouteInfo)
        this.searchTerm = toTerm;
        this.isDesktop() ? this.search({ shoppable: false }) : this.search();
        // reset flag to resume watching for facet and search change
        this.watchSearchTermAndFacets = true;
      } else if (isModifiedSearchChange) {
        if (this.searchTerm !== toTerm) {
          this.searchTerm = toTerm;
          this.search();
        }
      } else {
        if (toTerm !== '') {
          this.searchTerm = toTerm;
          this.setSearch(true);
          this.setRouteInfo();
        } else if (toDim !== '') {
          this.setSearch();
          this.setFacetsFromDimensions(toDim);
        } else {
          this.setRouteInfo();
        }
      }
    },
    routeInfo(to, from) {
      let tDim = to.dimensions || '';
      let tTerm = decodeURIComponent(to.term || '');
      let iDim = from.dimensions || '';
      let iTerm = decodeURIComponent(from.term || '');

      let haveFacetsChanged =
        tDim !== iDim && typeof from.dimensions !== 'undefined';
      let hasTermChanged = tTerm !== iTerm && typeof from.term !== 'undefined';

      if (haveFacetsChanged || hasTermChanged) {
        console.log('--- routeInfo change ---');
        console.log(
          `tDim: ${tDim}, iDim: ${iDim}\ntTerm: ${tTerm}, iTerm: ${iTerm}`,
        );
        this.callDynamicPageview(events.PAGE_VIEW);

        if (this.images.length) {
          console.log('call clearImages()...');
          this.hidePagination = false;
          this.clearImages();
          window.scrollTo(0, 0);
        } else {
          this.hidePagination = true;
        }
        this.resetNavScroll();
        this.offset = null;
        this.addImages().then(images => {
          this.setLighboxImg(images);
        });
        console.log('addImages() called...');
        console.log('--- /routeInfo change ---');
      } else {
        console.log('--- no change in facets or search ---');
      }
    },
  },
  created() {
    let vm = this;
    vm.preventScrollRestoration();
    vm.io = new IntersectionObserver(vm.observerCb, vm.observerOptions);
    vm.ninetyPercentWindowHeight = Math.round(window.innerHeight * 0.9);
    vm.pageviewThreshold = vm.ninetyPercentWindowHeight;
    window.addEventListener('scroll', () => {
      if (vm.images.length > 0) vm.bottom = vm.bottomVisible();
      vm.direction = vm.getScrollDirection();
      vm.sticky = vm.getSticky();
      vm.debounce(vm.pageviewCheck).call(this);
    });
    // Event handler used on browsers that support back/forward cache.  Other browsers will rely on visibility
    window.onpageshow = function(event) {
      if (event.persisted) {
        let lightboxId = '';
        // The app saves a lightbox ID before going to a single image page
        if (vm && vm.route && vm.route.params && vm.route.params.lightboxId) {
          lightboxId = vm.route.params.lightboxId;
        } else {
          return;
        }
        console.log(
          'From back / forward cache.  Scroll to the lightbox if available',
          lightboxId,
        );
        let el = document.querySelector(`[data-image-id="${lightboxId}"]`);
        console.log('Will scroll to:', el);
        if (el) {
          // Scroll into view if needed is a slightly better option in safari not in FF
          if (el.scrollIntoViewIfNeeded) el.scrollIntoViewIfNeeded(true);
          else el.scrollIntoView(true);
        }
      }
    };
    // Check for Ads/Analytics
    vm.checkDependencies();
    // Get Facets, images and config
    vm.setConfig();
    vm.setMap();
  },
  mounted() {
    this.setThreshold();
  },
  updated() {
    this.$nextTick(() => {
      if (this.io) {
        Array.from(document.querySelectorAll(`${this.imageSel}`)).forEach(
          entry => {
            this.io.observe(entry);
          },
        );
      }
    });
  },
  destroyed() {
    this.io && this.io.disconnect && this.io.disconnect();
  },
  methods: {
    ...mapActions({
      parseSet: 'feed/parseSet',
      clearPhotos: 'feed/clearPhotos',
      prependFacet: 'feed/prependFacet',
      resetLightbox: 'lightbox/resetLightbox',
      setActiveImage: 'lightbox/setActiveImage',
      addFacets: 'facets/addFacets',
      getFacetUrlLabel: 'facets/getFacetUrlLabel',
      selectFacetByIndex: 'facets/selectFacetByIndex',
      selectFacetByValue: 'facets/selectFacetByValue',
    }),
    getRandomBoolean(num = 0.6) {
      return Math.random() >= num;
    },
    applyTitleCase(value) {
      if (!value) return '';
      value = value.toString().toLowerCase();
      const regex = /\_/gi;
      return value
        .replace(regex, ' ')
        .split(' ')
        .map(letter => letter.charAt(0).toUpperCase() + letter.substring(1))
        .join(' ');
    },
    debounce(fn) {
      let timeout;

      return function() {
        const context = this;
        const args = arguments;

        if (timeout) {
          window.cancelAnimationFrame(timeout);
        }

        timeout = window.requestAnimationFrame(() => {
          fn.apply(context, args);
        });
      };
    },
    preventScrollRestoration() {
      if (history && 'scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
      }
    },
    checkDependencies() {
      if (checkDefined) {
        checkDefined(window, 'SniAds', () => {
          if (SniAds && SniAds.ready) {
            SniAds.ready(() => {
              checkDefined(window, 's', () => {
                if (s) {
                  checkDefined(window, 'SNI', () => {
                    if (SNI) {
                      checkDefined(SNI, 'Application', () => {
                        if (SNI.Application) {
                          this.ads = SNI.Application.getService('ads');
                          this.processAnalyticsQueue();
                        }
                      });
                    }
                  });
                }
              });
            });
          }
        });
      }
    },
    appendDynamicAd(e, t, p) {
      if (this.ads && !this.isDesktop()) {
        this.adCount++;
        const adContainer = document.createElement('div');
        adContainer.id = `feed_ad_${this.adCount}`;
        adContainer.classList.add('card', 'card--full');
        adContainer.setAttribute(this.adSel, 'true');
        e.insertAdjacentElement(p, adContainer);
        this.ads.lazyLoadAd({
          id: adContainer.id,
          type: t,
        });
        adContainer.insertAdjacentHTML(
          'beforeend',
          '<p class="ad-text">Advertisement</p>',
        );
      }
    },
    removeAds() {
      Array.from(
        document.querySelectorAll(`[${this.adSel}="true"]`),
      ).forEach(el => el.remove());
    },
    callDynamicPageview(forceEvent) {
      if (typeof s !== 'undefined' && s != null) {
        if (forceEvent) {
          s.events = forceEvent;
        }
        this.setSearchMdm();
        s.t();
      }
    },
    pageviewCheck() {
      const absScrollPos = Math.abs(this.scrollPos);
      if (absScrollPos > this.pageviewThreshold) {
        this.pageviewThreshold += this.ninetyPercentWindowHeight;
        this.pageviewCounter++;
        this.searchAction = '';
        this.callDynamicPageview();
        if (this.pageviewCounter % 3 == 0) {
          this.pageviewCounter = 0;
          if (
            this.adInjectionTarget &&
            this.adInjectionTarget.nextElementSibling &&
            !this.adInjectionTarget.nextElementSibling.dataset.feedAd
          ) {
            this.appendDynamicAd(
              this.adInjectionTarget,
              'dfp_bigbox',
              'afterend',
            );
          }
        }
      }
    },
    observerCb(entries) {
      let vm = this;
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let target = entry.target;
          if (vm.io) {
            if (!target.dataset.row) {
              vm.io.unobserve(entry.target);

              const regex = /^(https*\:\/\/[\w\.]+)/gi;
              const img = entry.target;
              const src = img.dataset.src;

              if (src) {
                img.setAttribute('src', '');
                img.setAttribute('src', src);
                img.onload = () => {
                  entry.target.removeAttribute('data-src');
                };

                vm.unobserveImage(img.src.replace(regex, ''));

                const parent = img.closest(`.${vm.cardSel}`);

                if (parent) {
                  vm.adInjectionTarget = parent;
                }

                // added a check to ensure image is shoppable before firing a product impression
                let id = img.dataset.imageId;
                let image = this.images.find(i => i.id === id);
                if (image && image.shoppable && image.productId) {
                  vm.trackProductImpression(vm.getMDM(img.dataset.productId));
                }
              }
            }
          }
        }
      });
    },
    getMDM(productId) {
      const p = this.products.filter(product => product.id === productId)[0];

      const mdm =
        p && p.products && p.products.length > 0
          ? {
              imagecount: 'n/a',
              partner: 'Wayfair',
              componentname: 'inspirationfeed',
              productcount: p.products.length,
              title: p.products.reduce(this.concatProdTitles, null),
            }
          : null;

      return mdm;
    },
    unobserveImage(src) {
      this.images.forEach(image => {
        if (image.image == src) image.track = false;
      });
    },
    setLocation(loc = 'home') {
      this.location = loc;
    },
    resetNavScroll() {
      const nav = document.querySelector(`.${this.navbar}`);
      if (nav) nav.scrollLeft = 0;
    },
    setChildren(item) {
      // if what I'm about to click on is not in the current facets push
      // if (item.children.length < 1) this.prependFacet(item.Value, e);
      const selectedChildren = this.facets.filter(facet =>
        facet.includes(`:2/${item.name}`),
      );
      if (selectedChildren.length < 1) this.prependFacet(item.Value);
      this.children = [...item.children];
    },
    setConfig() {
      const config = this.getAppConfig();
      if (config) {
        const empty =
          Object.keys(config).length === 0 && config.constructor === Object;

        if (!empty) {
          if (config.contextualTitle) {
            config.contextualTitle = `${config.contextualTitle} Ideas`;
            this.hasContextualTitle = true;
          }
          const options = Object.entries(config);
          for (const [prop, value] of options) {
            if (typeof this[prop] !== 'undefined') {
              this[prop] = value;
            }
          }
        }
        return true;
      }
      return false;
    },
    /*
      Retrieves Facets
      Reads Config
      Initializes Route data (OK to read terms, dims, lightbox)
      ALSO - sets base URL.  Should not be necessary on INIT
      Retrieve images
    */
    setMap() {
      let url = `${this.facetEndpoint}/dimensions%3Amap.html.json`;
      fetch(url)
        .then(response => response.json())
        .then(response => {
          this.addFacets(response);
          this.initializeRouteData();
          this.addImages(true, true)
            .then(images => {
              this.setLighboxImg(images);
            })
            .then(() => {
              console.log('Done with images');
              this.routerWatchFacets = true;
            })
            .catch(err => {
              console.error('Error during initial image fetch', err);
            });
        });
    },
    // Setting a reference to the actual image object
    setLighboxImg(images) {
      if (this.lightboxId && images.length > 0) {
        let lightboxImage = this.images.find(
          image => image.id == this.lightboxId,
        );
        if (lightboxImage) {
          this.foundLBImage = lightboxImage;
          if (this.appReadyForScroll) {
            this.scrollToLBImg();
          }
        }
      } else {
        // if no LB is present then you can scroll within the feed
        if (this.isLandingPage) {
          let el = document.querySelector('.o-FeedSection');
          this.isLandingPage = false;
          el.scrollIntoView(true);
        }
      }
    },
    // Scroll to Lightbox image
    // When coming back to the app and a lightbox image is part of the URL, we can call this function to scroll to it
    scrollToLBImg() {
      if (this.scrollToImage) {
        if (this.foundLBImage) {
          console.log(
            'A lightbox image was set, scrolling to it, if available',
          );
          this.scrollToActiveImage(this.scrollToImage);
        } else {
          console.log(
            'A lighbox image was set but the image was not retrieved.  Possibly a routing issue',
          );
        }
      }
    },
    // Perform the scroll to image, set it as active
    scrollToActiveImage(id) {
      if (id) {
        let el = document.querySelector(`[data-image-id="${id}"]`);
        if (el) {
          if (el.scrollIntoViewIfNeeded) el.scrollIntoViewIfNeeded(true);
          else el.scrollIntoView(true);
        }
      }
    },
    deflateFacet(facet) {
      if (!facet) return '';
      const match = this.tempMap.filter(item => item.value === facet);
      return match.length ? match[0].index : facet;
    },
    removeFacet(item) {
      if (typeof item === 'object') {
        if (item.parent) {
          this.facets = this.facets.filter(facet => facet != item.parent);
        } else {
          // this is a parent - remove all children of this parent
          this.facets = this.facets.filter(
            facet => !facet.includes(`:2/${item.name}`),
          );
        }
      } else if (typeof item === 'string') {
        this.facets = this.facets.filter(facet => facet !== item);
      }

      if (!this.facets.length) {
        this.resetFilters();
      }
    },
    pushShoppable() {
      if (!this.facets.includes('CUSTOM_FACET:SHOPPABLE')) {
        this.prependFacet('CUSTOM_FACET:SHOPPABLE');
        // this.facets.unshift('CUSTOM_FACET:SHOPPABLE');
      }
    },
    getFacetValidity(count) {
      return parseInt(count) < 1;
    },
    setThreshold() {
      let offsetHeight = 0;
      const els = {
        header: document.querySelector('.header'),
        banner: this.$el.querySelector('.banner--inspiration'),
        selected: this.$el.querySelector('.navbar-brand.has-shadow'),
      };

      for (let el in els) {
        if (els[el]) offsetHeight += els[el].clientHeight;
      }

      this.threshold = offsetHeight;
    },
    getSticky() {
      this.setThreshold();
      return (window.scrollY || window.pageYOffset) > this.threshold;
    },
    getScrollDirection() {
      const bodyTop = document.body.getBoundingClientRect().top;
      const dir = bodyTop > this.scrollPos ? 'up' : 'down';
      this.scrollPos = bodyTop;
      return dir;
    },
    bottomVisible() {
      const scrollY = window.scrollY || window.pageYOffset;
      const visible = document.documentElement.clientHeight + scrollY / 4;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    trackModule(metadata) {
      if (metadata && this.hasWindow && window.moduleTrack) {
        const name = metadata.name || 'inspirationFeed--Filter',
          title = metadata.title || 'n/a',
          position = metadata.position || 0,
          source = window.location.pathname,
          target =
            metadata.target ||
            `${window.location.pathname}${
              metadata.title ? '/' + metadata.title : ''
            }`,
          type = 'click';

        try {
          moduleTrack(this, name, title, position, source, target, type);
        } catch (e) {
          console.log('==== ERROR ====');
          console.log(e.message, e);
        }
      }
    },
    clearImages() {
      if (this.isDesktop() && this.resetCurrentRow) {
        this.resetCurrentRow();
      }
      this.clearPhotos();
      this.firstLoad = true;
      this.resetLightbox();
    },
    formatFacet(obj) {
      return `${obj.type.toUpperCase()}_SFACET:${obj.level}/${obj.val}#${
        obj.type
      }:${obj.val}`;
    },
    clearFacets(setRouteInfo = true) {
      this.facets = [];
      this.offset = null;
      this.pageNum = 0;
      if (setRouteInfo) {
        this.setRouteInfo();
      }
      this.anchorFilterWidth = 0;
    },
    setFacets(newFacets) {
      this.facets = newFacets.map(facet => this.formatFacet(facet));
    },
    // Calculates the URL params for the search endpoint
    getRouteInfo({ dataOnly = true, resetPage = false } = {}) {
      const vm = this;
      const params = new URLSearchParams();
      let isSearch = !!this.searchTerm;
      let offset = this.offset;
      let dimensions = '';
      this.deflatedFacets.forEach((facet, index, arr) => {
        if (facet.indexOf('/') > -1) {
          const f = vm.getFacetByValue(facet);
          if (f) {
            facet = f.index;
          } else {
            facet = null;
          }
        }
        if (facet) {
          vm.selectFacetByIndex(facet);
          return (dimensions += `${facet}${
            index != arr.length - 1 ? '~' : ''
          }`);
        }
      });
      if (isSearch && this.facets.length > 0) {
        this.facets.forEach(facet => {
          params.append('dimensions', facet);
        });
      } else if (this.deflatedFacets.length > 0) {
        params.append('dimensions', dimensions);
      }
      if (isSearch) {
        if (resetPage) {
          this.page = 0;
        }
        params.append('searchTerm', this.searchTerm);
      }
      if (resetPage || isNaN(offset)) {
        offset = 0;
      }

      params.append('offset', parseInt(offset));
      params.append('numOfResults', this.numOfResults);
      const formattedParams = encodeURIComponent(
        decodeURIComponent(params.toString())
          .replace(/\=/gi, ':')
          .replace(/\&/gi, ',')
          .replace(/\//gi, '|')
          .replace(/\+/gi, ' '),
      );
      let endpoint = isSearch ? this.searchEndpoint : this.facetEndpoint;
      const url = `${endpoint}/${formattedParams}.html.json`;

      if (dataOnly) {
        let currentRouteInfo = {
          url,
          term: encodeURIComponent(this.searchTerm),
          dimensions,
          page: offset,
        };
        if (this.lightboxId && typeof this.lightboxId !== 'undefined') {
          currentRouteInfo.lightboxId = this.lightboxId;
        }
        return currentRouteInfo;
      } else {
        return url;
      }
    },
    // sets offset value
    setNewOffset() {
      let newOffset =
        this.offset === null ||
        typeof this.offset === 'undefined' ||
        isNaN(this.offset) ||
        this.pageNum <= 1
          ? 0
          : (this.pageNum - 1) * parseInt(this.numOfResults);
      this.offset = newOffset;
    },
    setSearch(val = false) {
      this.isSearch = val;
    },
    showSearch(val = false) {
      this.searchVisible = val;
    },
    setFocus() {
      this.$nextTick(() => {
        if (this.$refs.search) this.$refs.search.focus();
      });
    },
    clearSearch() {
      this.searchTerm = '';
      this.selectedSearchTerm = '';
      this.contextualTitle = this.isDesktop() ? this.defaultTitle : false;
    },
    // Simpler search update used when a page loads (does not reset page numbers)
    setSearchTerm(term = '') {
      if (term != '') {
        this.searchTerm = term;
        this.setSearch(true);
        this.facets = [];
        this.selectedSearchTerm = this.searchTerm;
        this.contextualTitle = `${this.applyTitleCase(this.searchTerm)} Ideas`;
        this.trackModule({
          name: 'inspirationFeed--Search',
          title: `search/${encodeURI(this.searchTerm)}`,
        });
      }
    },
    updateSearchTerm(newTerm, doSearch = true) {
      if (newTerm) {
        this.searchTerm = newTerm;
        this.search({ updateRouteInfo: doSearch });
      }
    },
    search({
      shoppable = false,
      updateRouteInfo = true,
      resetPage = false,
    } = {}) {
      this.searchTerm =
        this.searchTerm === 'undefined' ||
        typeof this.searchTerm === 'undefined'
          ? ''
          : this.searchTerm;

      if (this.searchTerm != '') {
        this.setSearch(true);
        this.clearFacets(false);
        if (typeof shoppable === 'boolean' && shoppable) {
          this.setFacetsFromDimensions('1');
        }
        this.selectedSearchTerm = this.searchTerm;
        this.contextualTitle = `${this.applyTitleCase(this.searchTerm)} Ideas`;
        this.trackModule({
          name: 'inspirationFeed--Search',
          title: `search/${encodeURI(this.searchTerm)}`,
        });
      }
      this.offset = null;
      if (updateRouteInfo) {
        this.setRouteInfo({ resetPage });
      }
    },
    getProducts() {
      let vm = this;
      vm.resetProducts();
      if (vm.productIds) {
        const queue = vm.getProductSubsets();

        let urls = queue.map(
          subset => `${vm.vendor}/${subset.join(',')}.html.json`,
        );
        let chain = Promise.resolve(); // Chaining rest calls to ensure that all requests loads consequentially one by one

        urls.forEach(function(url) {
          chain = chain
            .then(() => fetch(url))
            .then(response => response.json())
            .then(response => {
              const resultSet = response['vendor-output-from-request'].result;
              if (resultSet) {
                vm.products.push(...resultSet);
              } else {
                console.warn('No results in vendor request'); // eslint-disable-line no-console
              }
            })
            .catch(err => err);
        });

        chain.then(() => {
          vm.productsReady = true;
          vm.firstLoad && (vm.firstLoad = false);
        });
      }
    },

    resetProducts() {
      if (this.firstLoad) {
        this.productsReady = false;
      }
    },

    getProductSubsets() {
      const productQueue = [];
      const numSubsets = Math.floor(this.productIds.length / this.subsetLength);
      const indexer = Array.apply(null, {
        length: numSubsets + 1,
      }).map(Number.call, Number);
      indexer.reduce((curr, next) => {
        productQueue.push(
          this.productIds.slice(
            curr * this.subsetLength,
            next * this.subsetLength,
          ),
        );
        return next;
      });
      productQueue.push(
        this.productIds.slice(
          numSubsets * this.subsetLength,
          this.productIds.length,
        ),
      );
      return productQueue.filter(queue => queue.length > 0);
    },
    getProduct(id) {
      const p = this.products.filter(product => {
        if (product.id === id) {
          return product.products;
        }
      });
      return p.length > 0 ? p[0].products : [];
    },
    concatProdTitles(str, product) {
      const prodWithVendor = `Wayfair|${product.name}`;
      return str ? (str += `,;${prodWithVendor}`) : prodWithVendor;
    },
    setSearchMdm() {
      if (mdManager && this.searchMetadata) {
        for (let param in this.searchMetadata) {
          mdManager.setParameter(param, this.searchMetadata[param]);
        }
      }
    },
    populateTagSet(tags, tag, unshift = false) {
      if (tag.Id.startsWith('1/')) {
        const regex = /^.*\:([0-9a-z\s\'\_\-\"\/]+)$/gi;
        const name = tag.Id.replace(regex, '$1').toLowerCase();
        const children = tags.filter(child => {
          if (child.Id.startsWith(`2/${name}`)) {
            const vegex = /^.*\:([0-9a-z\s\'\_\-\"\/]+\/)([0-9a-z\s\'\_\-\"\/]+)$/gi;
            const childName = child.Id.replace(vegex, '$2').toLowerCase();
            child.name = childName;
            child.parent = tag.Value;
            return child;
          }
        });
        tag.name = name;
        if (unshift && children.length > 0) {
          children.unshift({
            PageCount: tag.PageCount,
            Value: tag.Value,
            Id: tag.Id,
            name: name,
          });
        }
        tag.children = children;
        return tag;
      }
    },
    parseFacets(data) {
      if (data) {
        data.forEach(facet => {
          const category = Object.keys(facet);
          const tags = facet[category[0]];
          let top = '';
          switch (category[0]) {
            case 'ROOMS_AND_SPACES_SFACET':
              top = this.facets
                .filter(facet => facet.startsWith('ROOMS_AND_SPACES_SFACET:1/'))
                .map(facet => ({
                  PageCount: 1,
                  Value: facet,
                  Id: facet.replace('ROOMS_AND_SPACES_SFACET:', ''),
                }));
              tags.unshift(...top);
              this.rooms = tags.filter(tag =>
                this.populateTagSet(tags, tag, true),
              );
              break;
            case 'COLOR_SFACET':
              this.colors = tags.filter(tag => this.populateTagSet(tags, tag));
              break;
            case 'STYLE_SFACET':
              this.styles = tags.filter(tag => this.populateTagSet(tags, tag));
              break;
          }
        });
      }
    },
    addImages(showIsLoading = true, initialCall = false) {
      // Can't exit out on the first call until we know totalPages
      if (!initialCall) {
        if (
          this.pageNum > this.totalPages ||
          (this.pageNum > 1 && this.pageNum === this.lastViewedPage)
        )
          return;
      }

      let vm = this;

      return new Promise((resolve, reject) => {
        if (showIsLoading && !vm.offset && !vm.isLoading) {
          vm.isLoading = true;
        }

        // set offset value here to better integrate with router and so
        // params are added correctly to the url below
        vm.setNewOffset(initialCall);
        let routeInfo = vm.getRouteInfo();

        const { dimensions, term } = routeInfo;
        this.hashProps = {
          dimensions,
          term,
          itemsPerPage: this.numOfResults,
          itemsPerRow: 3,
        };

        console.log('--- run addImages() ---', 'Initial call', initialCall);
        console.log(`routeInfo:`, routeInfo);

        // save link to current method
        const _addImages = vm.addImages;

        // set empty value for current method to prevent execution while this method is running
        vm.addImages = () => Promise.resolve();

        fetch(routeInfo.url)
          .then(response => response.json())
          .then(response => {
            let set = response.ServiceResponse.results.records || [];
            set.route = routeInfo;
            let facetSet = response.ServiceResponse.results.facets || [];
            // TODO: facetSet is always truthy because it's either results.facets or an empty array.
            //       should vm be or null?
            if (facetSet) vm.parseFacets(facetSet);
            let noResults = set.length === 0 && vm.images.length === 0;
            // A 404 is needed when
            if (noResults && vm.initialPage) {
              console.log('Send to error page');
              window.location = window.location.origin + '/error-page/404';
            }

            vm.noResults = noResults;
            this.pageNum =
              parseInt(response.ServiceResponse.results.metadata.pageNum) || 1;
            this.totalPages =
              response.ServiceResponse.results.metadata.totalPages || 0;
            this.lastViewedPage = this.pageNum;
            vm.parseSet(set);
            // vm.getProducts();  Vendor API disabled
            if (vm.allowDynamicPageview) vm.callDynamicPageview();
            vm.isLoading = false;
            vm.initialPage = false;
            // restore default behavior of current method
            vm.addImages = _addImages;

            resolve(set);
          })
          .catch(e => {
            console.error('Unable to add images: ', e);

            // restore default behavior of current method
            vm.addImages = _addImages;

            reject(e);
          });
      });
    },
    resetFilters() {
      this.anchorFilterOptions = {};
      this.anchorFilterWidth = 0;

      this.setLocation();
      this.resetNavScroll();
    },
    setAnchorFilter(cfg) {
      this.anchorFilterOptions = {
        title: cfg.title || cfg.item.name,
        accent: cfg.accent || this.anchorFilterOptions.accent,
        item: cfg.item,
      };
    },
    handleAnchorUpdate(e) {
      this.anchorFilterWidth = e.width;
    },
    getRoute({ term, dimensions, page, rowIndex, lightboxId }) {
      if (!window.scrollY) {
        rowIndex = 0;
      }

      if (term === 'undefined') {
        term = '';
      }
      let path = '/';
      if (term) {
        path = `${path}term/${term}/`;
      }
      if (dimensions) {
        path = `${path}${dimensions}/`;
      }
      if (typeof page === 'undefined') {
        page = this.offset;
      }
      if (page > 0) {
        path = `${path}page/${page}/`;
      }
      if (this.isDesktop() && rowIndex > 0) {
        path = `${path}row/${rowIndex}/`;
      }
      if (lightboxId) {
        path = `${path}lightbox/${lightboxId}`;
      }
      return path;
    },
    updateRoute(routeData) {
      let vm = this;
      let path = vm.getRoute(routeData);
      vm.$router.push({ path });
    },

    /*
      Routing option # 2
      example: #/term/kitchen/img-link/63ad2d5c5d63774684d2dbb62012fa65
    */
    checkVueRouter() {
      // Determine if any route params can be used
      // Save the deeplink image ID (no longer lightbox)
      // If nothing is available return false
      let { params } = this.$route;

      if (!this.routeCheckDone) {
        this.routeCheckDone = true;
        if (params.lightboxId) {
          // Only set the image scroll value once, on app load
          this.scrollToImage = params.lightboxId;
        }
      }
      let { term, dimensions, page } = params;

      if (term || dimensions || page) {
        return params;
      }

      return false;
    },
    /*
      Routing option # 1
      /photos/kitchen-/p/1/
    */
    parsePageRoute() {
      // Get the search term and facets if there are any
      let isFiltered = this.isFilteredPage();
      let page = this.baseUrlPg();
      let pageVal;
      let offset;

      if (isFiltered) {
        let { facets, term } = this.getAppConfig();
        if (facets) this.facets = facets;
        if (term) this.term = term;
        // console.log('Setting filters', facets, term);
      }

      if (page) {
        try {
          pageVal = parseInt(page);
          if (!isNaN(pageVal)) {
            let pg = pageVal > 0 ? pageVal : 1; // prevent negative page numbers, etc.
            offset = (pg - 1) * this.numOfResults;
            this.pageNum = pg;
            this.offset = offset;
            this.initialPage = pg; // save for reference later
            if (!isFiltered) this.isLandingPage = true;
          }
        } catch (e) {
          console.log('error parsing page value from URL', e);
        }
      }

      // Term is set from the page config
      if (this.term) {
        this.setSearchTerm(this.term);
      } else {
        this.setFacetsFromDimensions(this.dimensions);
      }
      return page || isFiltered;
    },

    initializeRouteData() {
      /*
        Routing information:
        - Dimensions: Filter the results using a facet
        - Search Term: Filter the results using a search query
        - Page: Used to calculate the offset for the results query
        - Image Deep Link: Provide the use a way to scroll to their last place on the page.  Should not affect results, only relevant on load.
        - Row: Unused!

        There are several ways to initialize route data:
        1 - The backend might have information: example, the vue router is empty but we are on a search page or term/tag page
        2 - The Vue router may have information: example, when coming back to the app with a back button
        3 - No routing is present / i.e. we are on the home page
      */
      let routeInfo;

      let vueRoute = this.checkVueRouter();
      let pageRoute = this.parsePageRoute();

      this.initialRoute = vueRoute;
      this.initialPath = pageRoute;

      routeInfo = pageRoute || vueRoute;

      this.setRouteInfo({ initialRoutes: routeInfo });

      return routeInfo;
    },

    setFacetsFromDimensions(dimensions) {
      if (dimensions && dimensions.length) {
        let facets = [];
        if (dimensions) {
          let indices = dimensions.split('~');
          indices.forEach(idx => {
            if (idx) {
              let facetItem = this.tempMap.find(item => item.index === idx);
              facets.push(facetItem.value);
            }
          });
        }
        this.facets = facets;
      }
    },
    setRouteInfo({ resetPage = false, initialRoutes } = {}) {
      let r = initialRoutes || this.getRouteInfo({ resetPage });
      let info = {
        ...this.routeInfo,
      };
      info.dimensions = r.dimensions;
      info.term = r.term;

      this.setBaseUrl();
      this.routeInfo = {
        ...info,
      };
      const { dimensions, term } = this.routeInfo;
      this.hashProps = {
        dimensions,
        term,
        itemsPerPage: this.numOfResults,
        itemsPerRow: 3,
      };
    },
    pageTitle() {
      return document.querySelector('title').innerText;
    },
    fetchPageUrl(url) {
      if (url.indexOf('/p/') > -1) {
        this.pageUrl = url.substring(0, url.indexOf('p/'));
      } else if (window.location.hash) {
        this.pageUrl = url.substring(0, url.indexOf('#'));
      } else {
        this.pageUrl = url;
      }
    },
    fetchBaseUrl() {
      let path = `${location.pathname}`;
      let parts = [location.host, location.hash];
      let page = this.pageNum < 1 ? '' : `p/${this.pageNum}`;

      if (this.facetRoute) {
        parts = [location.host, this.facetRoute, `${page}`, location.hash];
      }

      if (this.searchTerm) {
        parts = [
          location.host,
          `/photos/${this.searchTerm}-`,
          `${page}`,
          location.hash,
        ];
      }

      if (!this.facetRoute && !this.searchTerm) {
        parts = [location.host, `${page}`, location.hash];
      }

      path = parts
        .filter(Boolean)
        .join('/')
        .replace('//', '/');
      let url = `${location.protocol}//${path}`;
      return url;
    },
    setBaseUrl() {
      //  We do not need to replace URL the first time the router initializes.  Hence, we return early but allow other calls to proceed.
      if (!this.initialized) {
        this.initialized = true;
        return;
      }
      const url = this.fetchBaseUrl();
      const action = this.initialized ? 'pushState' : 'replaceState';
      window.history[action]({}, this.pageTitle(), url);
    },
    getFacetByValue(value) {
      if (!value) return false;
      let name = value.split('/').pop();
      return this.facetList.find(facet => facet.value.split('/').pop() == name);
    },
    // Browse filter on Photo Inspiration landing page
    applyBrowseFilter({ item, index, category }) {
      const { title, position, location } = category;
      this.categoryClick(
        {
          title,
          position,
          location,
        },
        true,
      );
      this.filterItemClick({ item: item.data, position: index });
    },
    resetPagination() {
      this.pageNum = 0;
      this.lastViewedPage = 1;
    },
  },
};
