<template>
  <section class="o-Filter">
    <h3 class="o-Filter__a-Headline">
      {{ title }}
    </h3>
    <ul class="o-Filter__l-Promos">
      <li
        class="o-Filter__m-Promo"
        v-for="(item, index) in filtersList"
        :key="index"
      >
        <a
          class="o-Filter__m-PromoBlock"
          :href="itemUrl(item.value)"
          :title="item.title"
          @click.prevent="clickEventHandler(item, index)"
        >
          <img
            :src="`${item.imgSrc}${rend}`"
            :alt="item.title"
            loading="lazy"
          />
          <span>{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </section>
</template>
<script>
import roomFiltersData from './filter-room-data.json';
import { getRendition } from '@mixins/globals';
export default {
  name: 'FilterRoom',
  props: {
    title: {
      type: String,
      default: 'Browse by Room',
    },
  },
  computed: {
    filtersList() {
      return roomFiltersData;
    },
    rend() {
      return getRendition('promo', 'png');
    },
  },
  methods: {
    clickEventHandler(item, index) {
      this.$emit(item.title);
      this.$emit('browse-filter', {
        item,
        index,
        category: { title: 'room', position: 1, location: 'rooms' },
      });
    },
    itemUrl(term) {
      return (
        window.location.origin +
        '/rooms/viewer/' +
        term.toLowerCase().replace(/\s+/g, '-') +
        '-'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';
.o-Filter {
  &__a-Headline {
    display: inline-block;
    font-size: 36px;
  }
  &__m-PromoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3b4143;
    text-align: center;
    cursor: pointer;
    .m-ResponsiveImage {
      background: none;
    }
    span {
      margin-top: 10px;
    }
    img {
      width: 140px;
    }
  }
  @media only screen and (min-width: 994px) {
    width: auto;
    min-width: 994px;
    max-width: 1250px;
    margin: 100px auto 0;
    padding: 1rem 0;
    &__a-Headline {
      margin: 2rem 0 0 15px;
    }
    &__l-Promos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0px 0 1.5rem 1.5rem;
    }
    &__m-PromoBlock {
      span {
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
      }
    }
    &__m-Promo {
      margin: 35px 54px 35px 0;
    }
    &__a-PromoImage {
      width: 100%;
      border-radius: 500px;
      overflow: hidden;
      margin-bottom: 14px;
    }
    &__a-Title {
      font-weight: $font-weight-regular;
      line-height: 1.35;
      color: $gray-900;
      text-align: center;
    }
  }
  @media only screen and (max-width: 993px) {
    max-width: 100vw;
    margin: 25px auto 0 auto;
    &__a-Headline {
      font-size: 23px;
      margin: 0 0 0 16px;
    }
    ul > li:first-child {
      padding-left: 0.5rem;
    }
    &__l-Promos {
      display: flex;
      flex-wrap: wrap;
      > li:nth-child(odd) {
        width: calc(50% - 5px);
        float: left;
        clear: left;
      }
      > li:nth-child(2n + 2) {
        width: calc(50% - 5px);
        float: right;
        clear: none;
      }
      margin-bottom: 50px;
    }
    &__m-PromoBlock {
      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
      }
    }
    &__m-Promo {
      flex: 0 0 auto;
      margin: 15px 0;
    }
    &__a-PromoImage {
      height: 143px;
    }
    &__a-Title {
      margin-top: 1rem;
    }
  }
}
</style>
