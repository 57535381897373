<template>
  <div :class="accent" class="anchor-filter button is-flat">
    <button
      href="#"
      class="back-button"
      @click.prevent="handleBack"
      aria-label="go back to facet selection"
    >
      <svg
        class="icon-backArrow"
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="15"
        viewBox="0 0 9 15"
        aria-hidden="true"
      >
        <polygon
          fill="#202429"
          points="3.299 7.375 8.93 1.555 7.375 0 0 7.375 7.375 14.751 8.93 13.196 4.209 8.475"
        />
      </svg>
    </button>
    <span class="anchor-filter-text">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'AnchorFilter',

  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      title: '',
      key: 0,
    };
  },

  computed: {
    label() {
      this.incrementKey();

      const { item } = this.options;

      if (!item) {
        return this.options.title.split('_')[0];
      }

      let res = [];

      if (item.parent) {
        res = item.parent.match(/\/(.*)#/);
      } else {
        res =
          item.children && item.children.length
            ? item.Id.match(/\/(.*)#/)
            : item.Id.match(/#(.*):/);
      }

      let category = res && res[1] ? res[1] : '';

      return category.split('_')[0] || this.options.title;
    },

    accent() {
      return this.options.accent;
    },
  },

  watch: {
    key(val) {
      this.$emit('update', { width: this.$el.offsetWidth, key: val });
    },
  },

  methods: {
    incrementKey() {
      this.key++;
    },
    handleBack() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor-filter {
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 0.75rem 0 0;
  height: 2rem;

  .back-button {
    display: flex;
    padding: 0 0.75rem;
    background: transparent;
    border: none;
    cursor: pointer;

    .icon-backArrow {
      margin: 0;
    }
  }

  .anchor-filter-text {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      background: #ccc;
      display: block;
      width: 1px;
      height: 20px;
      margin: 0 0.75rem 0 0;
    }
  }
}
</style>
