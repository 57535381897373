import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      selectedCategory: '',
      selectedItems: this.emptySelectedItems(),
      forceUpdate: 1,
    };
  },

  created() {
    this.colorSequence = [
      'white',
      'gray',
      'neutral',
      'black',
      'black_and_white',
      'silver',
      'metallic',
      'gold',
      'brown',
      'red',
      'orange',
      'yellow',
      'green',
      'blue',
      'purple',
      'pink',
      'multicolor',
    ];

    this.locations = {
      rooms: 'ROOMS_AND_SPACES_SFACET',
      styles: 'STYLE_SFACET',
      colors: 'COLOR_SFACET',
    };
  },

  computed: {
    selectedCategoryItems() {
      if (!this.selectedItems) {
        return [];
      }
      const locationKey = this.locations[this.location];
      if (this.selectedItems && this.selectedItems.filter) {
        return this.forceUpdate && this.location === 'rooms'
          ? this.selectedItems.filter(
              item =>
                !item.Value.indexOf(locationKey) &&
                item.Value.match(this.activeItem.name),
            )
          : this.selectedItems.filter(item => !item.Value.indexOf(locationKey));
      }
      return [];
    },

    locationItems() {
      let children = this.children || {};
      let loc = this.location;
      const locItems =
        this.location === 'rooms'
          ? [...children]
          : loc && this[loc]
          ? [...this[loc]]
          : [];

      return this.forceUpdate ? locItems : [];
    },

    colorItems() {
      let arr = [];

      this.colorSequence.map(colorName => {
        for (let color of [
          ...this.locationItems,
          ...this.selectedCategoryItems,
        ]) {
          if (color.name === colorName) {
            arr.push(color);
            break;
          }
        }
      });

      return arr;
    },

    filterItems() {
      const items =
        this.location === 'colors'
          ? this.colorItems
          : this.sortArray(
              [...this.locationItems, ...this.selectedCategoryItems],
              'name',
            );

      if (this.isLoading) {
        return this.cachedFilterItems || items;
      }
      return (this.cachedFilterItems = items);
    },

    roomsParents() {
      return this.sortArray(this.rooms, 'name');
    },
  },

  methods: {
    ...mapMutations({
      clearSelectedFacet: 'facets/clearSelected',
    }),
    isFacetActive(facet = 'rooms') {
      let re = new RegExp(`^${facet}`);
      return (
        (this.selectedCategory.match(facet) && this.isNavbarOpen) ||
        this.facets.some(item => re.test(item))
      );
    },

    isRoomsCategoryActive(item) {
      return (
        (this.selectedItems &&
          this.selectedItems.some &&
          this.selectedItems.some(el => el.parent === item.Value)) ||
        (this.facets &&
          this.facets.some &&
          this.facets.some(el => el === item.Value))
      );
    },

    isRoomsItemActive({ name }) {
      return this.selectedItems && this.selectedItems.some
        ? this.selectedItems.some(item => item.name === name)
        : false;
    },

    getFacetStyles(facet) {
      return {
        'is-Active': this.isFacetActive(facet),
        'has-FiltersSet': this.selectedCategories.includes(facet),
      };
    },

    appendSelectedItem(item, setActiveItem = true) {
      if (item && this.selectedItems && this.selectedItems.every) {
        if (setActiveItem) {
          this.activeItem = item;
        }

        if (this.selectedItems.every(el => el.Value !== item.Value)) {
          this.selectedItems.push({
            ...item,
            isActive: true,
          });
        }

        this.forceUpdate++;
      }
    },

    shoppableClick() {
      this.isDesktop() ? this.collapseFilters() : this.setSearch();

      this.trackModule({ title: 'shop', position: 0 });
      this.pushShoppable();
      this.resetNavScroll();
    },

    filterItemClick({ item, position }) {
      this.resetPagination();
      this.appendSelectedItem(item);

      this.trackModule({ title: item.name, position });
      this.setAnchorFilter({ item });
      this.setChildren(item);
      this.resetNavScroll();

      if (!item.children || !item.children.length) {
        this.isDesktop() && this.collapseFilters();
      }
    },

    filterChildClick({ item, position }) {
      this.appendSelectedItem(item, false);

      this.trackModule({ title: item.name, position });
      this.setAnchorFilter({ item });
      this.prependFacet(item.Value);
      this.removeFacet(item);
      this.resetNavScroll();

      this.isDesktop() && this.collapseFilters();
    },

    categoryClick({ title, position, location }, isBrowse = false) {
      if (!isBrowse) {
        if (this.isDesktop()) {
          if (!this.selectedCategory) {
            this.expandFilters();
          } else {
            this.selectedCategory.match(title) && !this.children.length
              ? this.toggleFilters()
              : this.changeFilters();
          }
        }
      }

      this.selectedCategory =
        this.children && this.children.length ? title + '-children' : title;

      if (title === 'rooms') {
        this.children = [];
      }

      this.setSearch();
      this.trackModule({ title, position });
      this.setAnchorFilter({ title, accent: `is-accent--${title}` });
      this.setLocation(location);
      this.resetNavScroll();
    },

    emptySelectedItems() {
      return [];
    },

    sortArray(array, field) {
      if (field) {
        return array.sort((a, b) => {
          if (a[field] < b[field]) {
            return -1;
          }

          if (a[field] > b[field]) {
            return 1;
          }

          return 0;
        });
      }

      return array.sort();
    },

    removeSelectedFacet(facet) {
      this.removeFacet(facet);
      const facetObj = this.facetList.find(f => f.value === facet);
      this.clearSelectedFacet(facetObj);

      if (this.selectedItems && this.selectedItems.filter) {
        this.selectedItems = this.selectedItems.filter(
          item => item.Value !== facet,
        );

        this.isDesktop() && this.isNavbarOpen && this.collapseFilters();

        if (!this.facets.length) {
          this.clearAll();
        }

        this.forceUpdate++;
      }
    },

    clearAll() {
      this.clearSearch();
      this.setSearch();
      this.clearFacets();
      this.clearSelectedFacet();
      this.setLocation();
      this.resetNavScroll();

      this.selectedCategory = '';
      this.selectedItems = this.emptySelectedItems();
      this.selectedSearchTerm = '';

      this.isDesktop() && this.collapseFilters();
    },
  },
};
