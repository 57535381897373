export default {
  computed: {
    backgroundIndex() {
      let min = 1;
      let max = 6;
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    cardImageClass() {
      return `card-image--${this.backgroundIndex}`;
    },
    photoContainerAttrs() {
      let attrs = {};
      attrs.class = {
        'c-Feed--item': true,
      };
      return attrs;
    },
  },
  methods: {
    getSource(src) {
      return this.lazyload
        ? "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E"
        : src;
    },
  },
};
