import { Globals } from '@helpers';

const hasSniAds = () => {
  return Globals && Globals.hasWindow() && Globals.window.SniAds;
};

const allSlots = state => {
  let all = [];
  let types = Object.keys(state.slots);
  types.forEach(type => {
    all = all.concat(state.slots[type]);
  });
  return all;
};
const hasLeaderboard = state => {
  return (
    allSlots(state).findIndex(
      slot =>
        (slot &&
          (slot.indexOf('dfp_leaderboard') >= 0 ||
            slot.indexOf('dfp_smartphone_banner') >= 0)) ||
        -1,
    ) >= 0
  );
};
const getters = {
  allSlots,
  hasLeaderboard,
};

export default {
  namespaced: true,
  state: () => {
    return {
      slots: {
        page: [],
      },
      hasLeaderboardLoaded: false,
    };
  },
  getters,
  mutations: {
    setSlot(state, data) {
      state.slots[data.type].push(data.name);
    },
    setHasLeaderboardLoaded(state, hasLoaded) {
      state.hasLeaderboardLoaded = hasLoaded;
    },
    setRemoveSlot(state, { type, slot }) {
      state.slots[type].splice(state.slots[type].indexOf(slot), 1);
    },
  },
  actions: {
    refreshSlots(store, slotNames) {
      let adlib = hasSniAds();
      adlib && adlib.refreshSlots(slotNames);
    },
    refreshAds({ dispatch, state }, type) {
      dispatch('refreshSlots', state.slots[type]);
    },
    addSlot({ commit }, data) {
      commit('setSlot', data);
    },
    removeSlot({ commit }, data) {
      commit('setRemoveSlot', data);
    },
    leaderboardHasLoaded({ commit }, value) {
      commit('setHasLeaderboardLoaded', value);
    },
  },
};
