import Vue from 'vue';
import Vuex from 'vuex';
import feed from './modules/feed';
import advertisement from './modules/advertisement';
import lightbox from './modules/lightbox';
import { getField, updateField } from 'vuex-map-fields';
import facets from './modules/facets';
import labs from './labs';

Vue.use(Vuex);

const state = {
  routeInfo: {},
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

const actions = {};

const modules = {
  advertisement,
  feed,
  lightbox,
  facets,
  labs,
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  namespace: true,
  state,
  getters,
  mutations,
  actions,
  modules,
});
