<template>
  <div
    :class="{ isLoading: isLoading }"
    class="loader-overlay"
    aria-label="loading"
  >
    <figure aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <mask id="mask">
          <rect fill="#fff" x="0" y="0" width="100%" height="100%" />
          <circle id="circle-mask" cx="50%" cy="50%" r="0" fill="#000" />
        </mask>
        <g class="masked">
          <rect width="100%" height="100%" fill="#fff" opacity="0.9" />
          <circle
            class="circle"
            cx="50%"
            cy="50%"
            r="18"
            fill="#09ADC3"
            fill-rule="nonzero"
          />
          <circle
            class="circle"
            cx="50%"
            cy="50%"
            r="18"
            fill="#D864D4"
            fill-rule="nonzero"
          />
          <circle
            class="circle"
            cx="50%"
            cy="50%"
            r="18"
            fill="#A3C728"
            fill-rule="nonzero"
          />
          <circle
            class="circle"
            cx="50%"
            cy="50%"
            r="18"
            fill="#FC4367"
            fill-rule="nonzero"
          />
        </g>
      </svg>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Loader',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  watch: {
    isLoading() {
      this.isLoading ? this.playAnimation() : this.stopAnimation();
    },
  },

  mounted() {
    this.circles = this.$el.querySelectorAll('.circle');
    this.mask = this.$el.querySelector('#circle-mask');

    this.initStart();
    this.initEnd();

    this.isLoading && this.playAnimation();
    this.firstTime = true;
  },

  methods: {
    initStart() {
      this.fadeInAnimation = this.$anime({
        targets: this.$el,
        opacity: [0, 1],
        duration: 300,
        autoplay: false,
        easing: 'linear',
        begin: () => (this.isVisible = true),
        complete: () => this.circlesAnimation.play(),
      });

      this.circlesAnimation = this.$anime({
        targets: this.circles,
        r: [0, (el, i) => 18 + i * 1.5],
        direction: 'alternate',
        duration: 650,
        autoplay: false,
        delay: (el, i) => i * 650,
        loop: true,
      });
    },

    initEnd() {
      this.maskAnimation = this.$anime({
        targets: this.mask,
        r: [0, '100vmin'],
        duration: 425,
        easing: 'easeOutSine',
        autoplay: false,
        complete: () => {
          this.circlesAnimation.restart();
          this.circlesAnimation.pause();
          this.isVisible = false;
          this.firstTime = false;
          this.maskAnimation.restart();
          this.maskAnimation.pause();
        },
      });

      this.fadeOutAnimation = this.$anime({
        targets: this.$el,
        opacity: [1, 0],
        duration: 300,
        autoplay: false,
        easing: 'linear',
        complete: () => {
          this.circlesAnimation.restart();
          this.circlesAnimation.pause();
          this.isVisible = false;
          this.firstTime = false;
        },
      });
    },

    playAnimation() {
      this.fadeInAnimation.play();
    },

    stopAnimation() {
      this.firstTime
        ? setTimeout(this.fadeOutAnimation.play, 1000)
        : this.fadeOutAnimation.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  height: 50%;
  background: white;
  opacity: 0.9;
  display: none;
  &.isLoading {
    display: block;
  }

  > figure {
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .masked {
      mask: url(#mask);
    }
  }
}
</style>
