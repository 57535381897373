<template>
  <section>
    <FilterRoom
      @facetClick="removeSelectedFacet($event)"
      @browse-filter="applyBrowseFilter($event)"
    />
    <FilterStyle
      @facetClick="removeSelectedFacet($event)"
      @browse-filter="applyBrowseFilter($event)"
      @update-search-term="updateSearchTerm($event)"
    />
    <FilterColor
      @facetClick="removeSelectedFacet($event)"
      @browse-filter="applyBrowseFilter($event)"
      @update-search-term="updateSearchTerm($event)"
    />
  </section>
</template>

<script>
import FilterRoom from '@common/browse-filters/filter-room';
import FilterStyle from '@common/browse-filters/filter-style';
import FilterColor from '@common/browse-filters/filter-color';

export default {
  name: 'BrowseFilters',
  components: {
    FilterRoom,
    FilterStyle,
    FilterColor,
  },
  props: {
    path: {
      type: String,
      default: 'src/components/common/browse-filters/index.vue',
    },
  },
  data() {
    return { componentPath: this.path };
  },
  methods: {
    updateSearchTerm(term) {
      this.$emit('update-search-term', term);
    },
    applyBrowseFilter(item) {
      this.$emit('browse-filter', item);
    },
  },
};
</script>
