/* global SNI */
export default {
  data() {
    return {
      analyticsQueue: [],
    };
  },
  provide() {
    return {
      trackProductLink: this.trackProductLink,
    };
  },
  methods: {
    trackProductLink(product) {
      if (!this.trackShopping && SNI && SNI.Application) {
        this.trackShopping = SNI.Application.getService('track-shopping');
      }
      if (product && this.trackShopping) {
        const metadata = {
          partner: 'Wayfair',
          title: `Wayfair|${product.name}`,
          componentname: 'inspirationfeed',
          imagecount: 'n/a',
          productcount: 'n/a',
        };
        this.trackShopping.trackProductClick(metadata);
      }
    },
    trackProductImpression(mdm) {
      if (!mdm) {
        return;
      }
      if (
        !this.trackShopping &&
        window.SNI &&
        SNI.Application &&
        SNI.Application.getGlobal('s')
      ) {
        this.trackShopping = SNI.Application.getService('track-shopping');
      }

      if (mdm && this.trackShopping) {
        this.trackShopping.trackProductImpression(mdm);
      } else {
        this.analyticsQueue.push(mdm);
      }
    },
    processAnalyticsQueue() {
      if (this.analyticsQueue.length > 0) {
        for (let i = this.analyticsQueue.length - 1; i >= 0; i--) {
          this.trackProductImpression(this.analyticsQueue[i]);
          this.analyticsQueue.splice(i, 1);
        }
      }
    },
  },
};
