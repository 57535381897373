let win;
if (typeof window === 'object') {
  win = window;
}
const hasWindow = () => typeof win === 'object';
let g;
if (typeof global === 'object') {
  g = global;
}
const hasGlobal = () => typeof global === 'object';
let d;
if (hasWindow() && typeof document === 'object') {
  d = document;
}
const hasDocument = () => typeof d === 'object';

const hasOmniture = () => typeof window.s === 'object';

const getOmniture = () => {
  return hasOmniture() ? window.s : null;
};

const hasMdManager = () => typeof window.mdManager === 'object';

const getMdManager = () => {
  return hasMdManager() ? window.mdManager : null;
};

const hasAnalytics = () => typeof window.SNI.Analytics === 'object';

const getAnalytics = () => {
  return hasAnalytics() ? window.SNI.Analytics : null;
};

const supportsLazyLoading = () => {
  return 'loading' in HTMLImageElement.prototype;
};

export default {
  window: win,
  global: g,
  document: d,
  hasWindow,
  hasGlobal,
  hasDocument,
  hasOmniture,
  getOmniture,
  hasMdManager,
  getMdManager,
  hasAnalytics,
  getAnalytics,
  supportsLazyLoading: supportsLazyLoading(),
  getGlobal() {
    return win || g || false;
  },
};
