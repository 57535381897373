import Vue from 'vue';

window.inspo = Vue.observable({
  showImageIndex: false,
});

let config = {
  breakpoint: {
    xs: 768,
  },
  localhost: {
    url: {
      content: 'http://photos.hgtv.core-qa.sni.hgtv.com',
    },
  },
};

let renditions = {
  promo: {
    mobile: '.rend.hgtvcom.196.196.',
    desktop: '.rend.hgtvcom.336.336.',
  },
  colors: {
    mobile: '.rend.hgtvcom.91.91.',
    desktop: '.rend.hgtvcom.266.266.',
  },
};

let appConfig;

function setAppConfig() {
  const configEl = document.querySelector('#config');
  if (configEl) {
    try {
      const config = JSON.parse(configEl.innerHTML);
      const empty =
        Object.keys(config).length === 0 && config.constructor === Object;

      if (!empty) {
        appConfig = config;
      }
    } catch (e) {
      // could not retrieve page config
      console.error(e);
    }
  }
}

export const getAppConfig = () => {
  if (!appConfig) setAppConfig();
  console.log('Appconfig', appConfig);
  return appConfig;
};

export const hasGlobal = () => {
  return typeof global === 'object';
};

export const hasWindow = () => {
  return typeof window === 'object';
};

export const hasDocument = () => {
  return hasWindow() && typeof document === 'object';
};

export const isMobile = () => {
  if (hasDocument()) {
    return document.documentElement.clientWidth < config.breakpoint.xs;
  }
  return true;
};

export const isDesktop = () => {
  if (hasDocument()) {
    return document.documentElement.clientWidth >= config.breakpoint.xs;
  }
  return false;
};

export const getRendition = (type, ext) => {
  let device = isMobile() ? 'mobile' : 'desktop';
  return `${renditions[type][device]}${ext || ''}`;
};

export const getBreakpoint = () => {
  return isMobile() ? 'xs' : 'lg';
};

export const isLocalhost = () => {
  return (
    hasDocument() &&
    document.location &&
    document.location.href.indexOf('localhost') > -1
  );
};

export const urlProxy = url => {
  if (url.indexOf('/content') === 0) {
    return url;
  }
  if (this.isLocalhost()) {
    return `http://localhost:9090/${this.urlPrefix(url)}`;
  }
  return url;
};

export const urlPrefix = url => {
  if (isLocalhost() && url.indexOf('http') < 0 && url.indexOf('//') !== 0) {
    return `${config.localhost.url.content}${url}`;
  }
  return url;
};

export const baseUrlPg = () => {
  // check location for a page number
  let loc = location.pathname;
  let pgInfo;
  pgInfo = /\/p\/\d+/.exec(loc || ''); // Get the part of the URL that matches /p/[digits]
  if (pgInfo) {
    let pgpath = pgInfo[0];
    return pgpath.replace('/p/', '');
  }
  return false;
};

export const showImageIndex = () => window.inspo.showImageIndex;

export const hasClass = ($el, className) => {
  if ($el) {
    if ($el.classList && $el.classList.contains) {
      return $el.classList.contains(className);
    }
    let classes = ' ' + $el.classes + ' ';
    return classes.indexOf(' ' + className + ' ') > -1;
  }
  return false;
};

export const isFilteredPage = () => {
  // Any page which has a term/search term/ facets
  setAppConfig();
  let { facets, term, hasSearchTerm } = appConfig;
  let isFiltered = (facets && facets.length) || term || hasSearchTerm;

  return Boolean(isFiltered);
};

export default {
  computed: {
    hasGlobal,
    hasWindow,
    hasDocument,
    showImageIndex,
  },
  methods: {
    isMobile,
    isDesktop,
    getBreakpoint,
    isLocalhost,
    urlProxy,
    urlPrefix,
    hasClass,
    getAppConfig,
    isFilteredPage,
    baseUrlPg,
  },
};
