import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

function routes(component) {
  const childRoutes = (prefix = '') => [
    {
      path: 'page/:page',
      component,
      props: true,
      name: `${prefix}page`,
      children: [
        {
          path: 'row/:rowIndex',
          component,
          props: true,
          name: `${prefix}row-nested`,
          children: [
            {
              path: 'lightbox/:lightboxId',
              component,
              props: true,
              name: `${prefix}lightbox-nested-all`,
            },
          ],
        },
      ],
    },
    {
      path: 'page/:page',
      component,
      props: true,
      name: `${prefix}page`,
      children: [
        {
          path: 'lightbox/:lightboxId',
          component,
          props: true,
          name: `${prefix}lightbox-nested-page`,
        },
      ],
    },
    {
      path: 'row/:rowIndex',
      component,
      props: true,
      name: `${prefix}row`,
      children: [
        {
          path: 'lightbox/:lightboxId',
          component,
          props: true,
          name: `${prefix}lightbox-nested-row`,
        },
      ],
    },
    {
      path: 'lightbox/:lightboxId',
      component,
      props: true,
      name: `${prefix}lightbox`,
    },
  ];

  return new Router({
    routes: [
      {
        path: '/term/:term',
        name: 'term',
        component,
        props: true,
        children: [
          {
            path: ':dimensions?',
            name: 'dimensionWithTerm',
            component,
            props: true,
            children: childRoutes('term-'),
          },
        ],
      },
      {
        path: '/:dimensions?',
        name: 'dimension',
        component,
        props: true,
        children: childRoutes(),
      },
      {
        path: '/lightbox/:lightboxId',
        component,
        props: true,
        name: 'lightbox',
      },
    ],
  });
}

export default routes;
