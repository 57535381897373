<template>
  <section v-if="showPromo" class="o-FacetPromo">
    <h3 class="o-FacetPromo__a-Headline">
      {{ title }}
    </h3>
    <ul class="o-FacetPromo__l-Promos">
      <li
        class="o-FacetPromo__m-Promo"
        v-for="(item, index) in facetPromoList"
        :key="index"
      >
        <a
          class="o-FacetPromo__m-PromoBlock"
          :href="itemUrl(item.event.value)"
          @click.prevent="clickEventHandler(item)"
        >
          <div class="m-ResponsiveImage m-ResponsiveImage--1x1">
            <img
              class="o-FacetPromo__a-PromoImage"
              :src="`${item.imgSrc}${rend}`"
              :alt="item.title"
              loading="auto"
            />
          </div>
          <h5 class="o-FacetPromo__a-Title">
            {{ item.title }}
          </h5>
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
import facetPromoData from './facet-promo-data.json';
import { getRendition } from '@mixins/globals';

export default {
  name: 'FacetPromo',
  props: {
    title: {
      type: String,
      default: 'Explore What’s Trending',
    },
    showPromo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    facetPromoList() {
      return facetPromoData;
    },
    rend() {
      return getRendition('promo', 'jpeg');
    },
  },
  methods: {
    itemUrl(term) {
      return (
        window.location.origin +
        '/photos/' +
        term.toLowerCase().replace(/\s+/g, '-') +
        '-'
      );
    },
    clickEventHandler(item) {
      this.$emit(item.event.name, item.event.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/variables/index.scss';

.o-FacetPromo {
  &__a-Headline {
    display: inline-block;
    font-size: 12px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-bottom: 3px solid $room-accent;
  }

  @media only screen and (min-width: 994px) {
    width: auto;
    min-width: 994px;
    max-width: 1250px;
    margin: 0 auto -7rem;
    padding: 1rem 0;

    &__a-Headline {
      margin: 2rem 0 1.5rem 1.5rem;
      font-size: 36px;
      font-weight: 300;
      text-transform: capitalize;
      border-bottom: none;
      margin: 2rem 0 1.5rem 15px;
    }

    &__l-Promos {
      display: flex;
      flex-direction: row;
      justify-content: left;
      flex-wrap: wrap;
    }

    &__m-PromoBlock {
      cursor: pointer;
    }

    &__m-Promo {
      margin: 0 15px;
      flex: 0 0 auto;
    }

    &__a-PromoImage {
      width: 280px;
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 14px;
    }

    &__a-Title {
      padding-bottom: 17px;
      max-width: 283px;
      font-weight: $font-weight-regular;
      font-size: 23px;
      line-height: 1.35;
      color: #3b4143;
      text-align: left;
    }
  }

  @media only screen and (max-width: 993px) {
    max-width: 100vw;
    padding-top: 26px;

    ul > li:first-child {
      padding: 0;
    }

    &__l-Promos {
      padding-left: 16px;
      margin-bottom: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      justify-content: flex-start;
    }

    &__m-PromoBlock {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-right: 1rem;
      margin-left: 0;
    }

    &__m-Promo {
      flex: 0 0 auto;
    }

    &__a-Headline {
      margin-bottom: 20px;
      margin-left: 16px;
      font-size: 23px;
      font-weight: 300;
      text-transform: capitalize;
      border-bottom: none;
    }

    &__a-PromoImage {
      width: 163px;
      height: 160px;
      border-radius: 4px;
    }

    &__a-Title {
      margin-top: 0.5rem;
      margin-bottom: 10px !important;
      max-width: 160px;
      width: 100%;
      font-size: 18px;
      line-height: 23px !important;
      color: #3b4143;
      text-align: left;
    }
  }

  @media only screen and (min-device-width: 481px) and (max-width: 993px) {
    padding-top: 48px;
  }
}
</style>
