<template>
  <div v-if="!hidePagination">
    <div v-if="hideNumbers" class="outer-wrapper">
      <ul
        :class="[
          containerClass,
          firstPageSelected() && isMobileDevice ? 'alignRight' : '',
        ]"
        v-if="!noLiSurround"
      >
        <li
          v-if="!(firstPageSelected() && hidePrevNext)"
          :class="[prevClass, firstPageSelected() ? disabledClass : '']"
        >
          <svg
            class="prev"
            width="20"
            height="36"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.632 23.264C11.464 23.264 11.3004 23.1956 11.1862 23.0778L0.186182 12.0778C0.0632727 11.9549 0 11.8007 0 11.632C0 11.4633 0.0683636 11.3004 0.186182 11.1862L11.1862 0.186182C11.3091 0.0632727 11.4676 0 11.6407 0C11.8087 0 11.9724 0.0683636 12.0865 0.186182L13 1.104C13.2407 1.34982 13.2407 1.74909 12.9956 1.99927L3.37745 11.6356L13 21.2582C13.2451 21.5033 13.2451 21.9033 13 22.1535L12.0865 23.0764C11.9636 23.1949 11.8095 23.2625 11.632 23.2625C11.6407 23.2625 11.6407 23.2625 11.632 23.2625V23.264Z"
              fill="#2CB6CA"
            />
          </svg>
          <a
            :href="pageHref(this.selected - 1)"
            @click.prevent="prevPage()"
            @keyup.enter="prevPage()"
            :class="prevLinkClass"
            :tabindex="firstPageSelected() ? -1 : 0"
            v-html="prevText"
          ></a>
        </li>
        <li
          v-if="!(lastPageSelected() && hidePrevNext)"
          :class="[nextClass, lastPageSelected() ? disabledClass : '']"
        >
          <a
            :href="pageHref(this.selected + 1)"
            @click.prevent="nextPage()"
            @keyup.enter="nextPage()"
            :class="nextLinkClass"
            :tabindex="lastPageSelected() ? -1 : 0"
            v-html="nextText"
          ></a>
          <svg
            class="next"
            width="20"
            height="36"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.60088 0C1.77413 0 1.94288 0.0705 2.06063 0.192L13.4044 11.5358C13.5311 11.6625 13.5964 11.8215 13.5964 11.9955C13.5964 12.1695 13.5259 12.3375 13.4044 12.4552L2.06063 23.799C1.93388 23.9257 1.77037 23.991 1.59187 23.991C1.41337 23.991 1.24988 23.9205 1.13213 23.799L0.190125 22.8525C-0.058125 22.5998 -0.058125 22.1873 0.194625 21.9293L10.1134 11.9917L0.190125 2.0685C-0.063375 1.81575 -0.063375 1.40325 0.190125 1.14525L1.13213 0.1935C1.25888 0.07125 1.42238 0.0015 1.60088 0.0015V0.0015V0Z"
              fill="#0FADC4"
            />
          </svg>
        </li>
      </ul>
    </div>
    <div v-else>
      <ul :class="containerClass" v-if="!noLiSurround">
        <li
          v-if="firstLastButton"
          :class="[pageClass, firstPageSelected() ? disabledClass : '']"
        >
          <a
            :href="pageHref(1)"
            @click.prevent="selectFirstPage()"
            @keyup.enter="selectFirstPage()"
            :class="pageLinkClass"
            :tabindex="firstPageSelected() ? -1 : 0"
            v-html="firstButtonText"
          ></a>
        </li>

        <li
          v-if="!(firstPageSelected() && hidePrevNext)"
          :class="[
            prevClass,
            'prev-button',
            firstPageSelected() ? disabledClass : '',
          ]"
        >
          <svg
            class="prev"
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.632 23.264C11.464 23.264 11.3004 23.1956 11.1862 23.0778L0.186182 12.0778C0.0632727 11.9549 0 11.8007 0 11.632C0 11.4633 0.0683636 11.3004 0.186182 11.1862L11.1862 0.186182C11.3091 0.0632727 11.4676 0 11.6407 0C11.8087 0 11.9724 0.0683636 12.0865 0.186182L13 1.104C13.2407 1.34982 13.2407 1.74909 12.9956 1.99927L3.37745 11.6356L13 21.2582C13.2451 21.5033 13.2451 21.9033 13 22.1535L12.0865 23.0764C11.9636 23.1949 11.8095 23.2625 11.632 23.2625C11.6407 23.2625 11.6407 23.2625 11.632 23.2625V23.264Z"
              fill="#2CB6CA"
            />
          </svg>
          <a
            :href="pageHref(this.selected - 1)"
            @click.prevent="prevPage()"
            @keyup.enter="prevPage()"
            :class="prevLinkClass"
            :tabindex="firstPageSelected() ? -1 : 0"
            v-html="prevText"
          ></a>
        </li>

        <li
          v-for="(page, index) in pages"
          :key="index"
          :class="[
            pageClass,
            page.selected ? activeClass : '',
            page.disabled ? disabledClass : '',
            page.breakView ? breakViewClass : '',
          ]"
        >
          <a
            v-if="page.breakView"
            :class="[pageLinkClass, breakViewLinkClass]"
            tabindex="0"
            ><slot name="breakViewContent">{{ breakViewText }}</slot></a
          >
          <a v-else-if="page.disabled" :class="pageLinkClass" tabindex="0">{{
            page.content
          }}</a>
          <a
            :href="pageHref(page.index + 1)"
            v-else
            @click.prevent="handlePageSelected(page.index + 1)"
            @keyup.enter="handlePageSelected(page.index + 1)"
            :class="pageLinkClass"
            tabindex="0"
            >{{ page.content }}</a
          >
        </li>

        <li
          class="next-button"
          v-if="!(lastPageSelected() && hidePrevNext)"
          :class="[nextClass, lastPageSelected() ? disabledClass : '']"
        >
          <a
            :href="pageHref(this.selected + 1)"
            @click.prevent="nextPage()"
            @keyup.enter="nextPage()"
            :class="nextLinkClass"
            :tabindex="lastPageSelected() ? -1 : 0"
            v-html="nextText"
          ></a>
          <svg
            class="next"
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.60088 0C1.77413 0 1.94288 0.0705 2.06063 0.192L13.4044 11.5358C13.5311 11.6625 13.5964 11.8215 13.5964 11.9955C13.5964 12.1695 13.5259 12.3375 13.4044 12.4552L2.06063 23.799C1.93388 23.9257 1.77037 23.991 1.59187 23.991C1.41337 23.991 1.24988 23.9205 1.13213 23.799L0.190125 22.8525C-0.058125 22.5998 -0.058125 22.1873 0.194625 21.9293L10.1134 11.9917L0.190125 2.0685C-0.063375 1.81575 -0.063375 1.40325 0.190125 1.14525L1.13213 0.1935C1.25888 0.07125 1.42238 0.0015 1.60088 0.0015V0.0015V0Z"
              fill="#0FADC4"
            />
          </svg>
        </li>
        <li
          v-if="firstLastButton"
          :class="[pageClass, lastPageSelected() ? disabledClass : '']"
        >
          <a
            :href="pageHref(this.pageCount)"
            @click.prevent="selectLastPage()"
            @keyup.enter="selectLastPage()"
            :class="pageLinkClass"
            :tabindex="lastPageSelected() ? -1 : 0"
            v-html="lastButtonText"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => {},
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevText: {
      type: String,
      default: 'Prev',
    },
    nextText: {
      type: String,
      default: 'Next',
    },
    breakViewText: {
      type: String,
      default: '…',
    },
    containerClass: {
      type: String,
    },
    pageClass: {
      type: String,
    },
    pageLinkClass: {
      type: String,
    },
    prevClass: {
      type: String,
    },
    prevLinkClass: {
      type: String,
    },
    nextClass: {
      type: String,
    },
    nextLinkClass: {
      type: String,
    },
    breakViewClass: {
      type: String,
    },
    breakViewLinkClass: {
      type: String,
    },
    activeClass: {
      type: String,
      default: 'active',
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    noLiSurround: {
      type: Boolean,
      default: false,
    },
    firstLastButton: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: 'First',
    },
    lastButtonText: {
      type: String,
      default: 'Last',
    },
    hidePrevNext: {
      type: Boolean,
      default: false,
    },
    hideNumbers: {
      type: Boolean,
      default: false,
    },
    pageUrl: {
      type: String,
      default: '',
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    hashProps: {
      type: Object,
      default: () => ({}),
    },
    isMobileDevice: {
      type: Boolean,
      default: false,
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  computed: {
    selected: {
      get: function() {
        return this.value || this.innerValue;
      },
      set: function(newValue) {
        this.innerValue = newValue;
      },
    },
    pages: function() {
      let items = {};
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1,
          };
          items[index] = page;
        }
      } else {
        const halfPageRange = Math.floor(this.pageRange / 2);

        let setPageItem = index => {
          let page = {
            index: index,
            content: index + 1,
            selected: index === this.selected - 1,
          };

          items[index] = page;
        };

        let setBreakView = index => {
          let breakView = {
            disabled: true,
            breakView: true,
          };

          items[index] = breakView;
        };

        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++) {
          setPageItem(i);
        }

        // 2nd - loop thru selected range
        let selectedRangeLow = 0;
        if (this.selected - halfPageRange > 0) {
          selectedRangeLow = this.selected - 1 - halfPageRange;
        }

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1;
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
        }

        for (
          let i = selectedRangeLow;
          i <= selectedRangeHigh && i <= this.pageCount - 1;
          i++
        ) {
          setPageItem(i);
        }

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages) {
          setBreakView(selectedRangeLow - 1);
        }

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
          setBreakView(selectedRangeHigh + 1);
        }

        // 3rd - loop thru high end of margin pages
        for (
          let i = this.pageCount - 1;
          i >= this.pageCount - this.marginPages;
          i--
        ) {
          setPageItem(i);
        }
      }
      return items;
    },
  },
  data() {
    return {
      innerValue: 1,
    };
  },
  methods: {
    pageHref(pgNum) {
      const { dimensions, term, itemsPerPage, itemsPerRow } = this.hashProps;
      const rowsPerPage = itemsPerPage / itemsPerRow;
      const page = pgNum <= 1 ? `` : `page/${itemsPerPage * (pgNum - 1)}/`;
      let row = '';
      if (!this.isMobileDevice) {
        row = `row/${pgNum > 1 ? pgNum * rowsPerPage : 1}`;
      }
      if (this.pageUrl !== '') {
        if (term) {
          return `${this.pageUrl}p/${pgNum}/#/term/${term}/${page}${row}`;
        } else if (dimensions) {
          return `${this.pageUrl}p/${pgNum}/#/${dimensions}/${page}${row}`;
        } else {
          return `${this.pageUrl}p/${pgNum}/#/${page}${row}`;
        }
      } else {
        return 'http://www.photos.hgtv.com';
      }
    },
    handlePageSelected(selected) {
      if (this.selected === selected) return;

      this.innerValue = selected;
      this.$emit('input', selected);
      this.clickHandler(selected);
    },
    prevPage() {
      if (this.selected <= 1) return;

      this.handlePageSelected(this.selected - 1);
    },
    nextPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.selected + 1);
    },
    firstPageSelected() {
      return this.selected === 1;
    },
    lastPageSelected() {
      return this.selected === this.pageCount || this.pageCount === 0;
    },
    selectFirstPage() {
      if (this.selected <= 1) return;

      this.handlePageSelected(1);
    },
    selectLastPage() {
      if (this.selected >= this.pageCount) return;

      this.handlePageSelected(this.pageCount);
    },
  },
};
</script>

<style lang="css" scoped>
a {
  cursor: pointer;
}
.prev-button {
  margin-right: 17px;
}
.next-button {
  margin-left: 17px;
}
.prev {
  margin-right: 10px;
}
.next {
  margin-left: 10px;
}
.alignRight {
  justify-content: flex-end;
}
@media only screen and (max-width: 993px) {
  .outer-wrapper {
    width: 100%;
  }
  ul {
    display: flex;
    justify-content: space-between;
  }
  li {
    display: flex;
    align-items: center;
  }
  li > a {
    font-size: 37px;
    text-transform: uppercase;
    color: #202429;
  }
}
</style>
