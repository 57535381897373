import { hasWindow, hasDocument } from './globals';

/**
 * Mixin to add an event listener to the document and destroy it when the component is destroyed.
 */

export const addListener = (element, eventName, handler) => {
  let vm = this;
  if (hasWindow() && hasDocument()) {
    // add the listener
    element.addEventListener(eventName, handler);

    if (vm) {
      // remove the listener when the component is destroyed
      vm.$once('hook:destroyed', () => {
        element.removeEventListener(eventName, handler);
      });
    }
  }
};

export const documentAddListener = (eventName, handler) => {
  addListener(document, eventName, handler);
};

export const $onResize = fn => {
  let vm = this;
  if (hasWindow() && typeof fn === 'function') {
    vm.addListener(window, 'resize', () => vm.debounce(fn).call(this));
  }
};

export default {
  methods: {
    addListener,
    documentAddListener,
    $onResize,
  },
};
