<template>
  <button :class="styles" @click.prevent.stop.capture="handleClick">
    <slot>Filter</slot>

    <span v-if="selected" class="button__icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
      >
        <path
          stroke="#202429"
          stroke-width="1.5"
          d="M6 1C6 1 4.33333333 2.66666667 1 6M1 1C2.16490568 2.16490568 3.83157234 3.83157234 6 6"
        />
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  name: 'ButtonFilter',

  props: {
    type: {
      type: String,
      default: 'default',
    },

    title: {
      type: String,
      default: '',
    },

    selected: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styles() {
      return [
        'button button__filter',
        `button__filter--${this.type}`,
        {
          'button__filter-selected': this.selected,
          'is-Active': this.isActive,
          'is-Disabled': this.isDisabled,
        },
        this.type !== 'rooms' ? `color--${this.title.replace(/ /g, '_')}` : '',
      ];
    },
  },

  methods: {
    handleClick(e) {
      e.target.blur();

      this.$emit('click', this);

      this.isActive ? this.$emit('toggleOff') : this.$emit('toggleOn');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles/common.scss';

.button__filter {
  $border__radius: $button__height * 2;

  overflow: inherit;

  border-radius: $border__radius;
  border: $border__width solid transparent;

  background-clip: padding-box;
  background: #fff;

  &.is-Active {
    color: #fff;
    border-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  &::before {
    $offset: $border__width * 2;

    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    margin: -#{$offset};
    border-radius: inherit;

    transition: background 100ms ease-in-out;
  }

  &:hover,
  &:focus {
    border-color: transparent;
    outline: none;
  }

  &--rooms {
    $color: map-get($facet_colors, Room);
    color: $color;

    &::before {
      background: $color;
    }

    &:hover {
      color: $color;

      &::before {
        background: saturate(darken($color, 8%), $active__saturation);
      }
    }

    &.is-Active {
      &,
      &::before {
        background: $color;
      }

      &:hover {
        &,
        &::before {
          color: map-get($filter_colors, White);
          background: saturate(darken($color, 2%), $active__saturation);
        }
      }
    }
  }

  &--style {
    $color: map-get($facet_colors, Style);
    color: $color;

    &::before {
      background: $color;
    }

    &:hover,
    &:focus {
      color: $color;
      border-color: transparent;
      &::before {
        background: saturate(darken($color, 8%), $active__saturation);
      }
    }

    &.is-Active {
      &,
      &::before {
        background: $color;
        border-color: transparent;
      }

      &:hover,
      &:focus {
        &,
        &::before {
          color: map-get($filter_colors, White);
          background: saturate(darken($color, 2%), $active__saturation);
          border-color: transparent;
        }
      }
    }
  }

  &--color {
    color: #202429;

    @each $color-key, $color-value in $filter_colors {
      &.color--#{$color-key} {
        &:before {
          background: $color-value;
        }

        @if $color-key == Gray {
          background: #fff;
        }

        @if $color-key == White {
          $color: #202429;

          color: $color;

          &.is-Active {
            background: #fff;

            &:before {
              background: $color;
            }
          }
        }
      }
    }

    &:hover,
    &:focus {
      border-color: transparent;
    }

    &.is-Active {
      background: transparent;
    }
  }

  &-selected {
    $height: 19px;
    $border-width: 3px;

    height: $height;

    font-size: 12px;

    border-radius: 0;
    border-width: 0;

    padding-left: 0;
    padding-right: 0;
    padding-bottom: 7px;

    color: #202429;

    margin: 4px 10px 4px 0;

    .button__icon {
      margin-left: 7px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &::before {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: -#{($border-width)};

      transform: scaleX(1);
      transform-origin: center left;
      transition: transform 175ms ease-out;
    }

    &.button__filter {
      &--rooms {
        &:before {
          background: map-get($facet_colors, RoomAccent);
        }
      }
      &--style {
        &:before {
          background: map-get($facet_colors, StyleAccent);
        }
      }
      &--shop {
        &:before {
          background: map-get($facet_colors, ShopAccent);
        }
      }
      &--search {
        &:before {
          background: map-get($facet_colors, Default);
        }
      }
    }

    &:hover {
      &::before {
        transform-origin: center right;
        transform: scaleX(0);
      }
    }
  }
}
</style>
