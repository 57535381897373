/*
  Using vuex-map-fields module for simple 2-way data binding
  in components:  https://github.com/maoberlehner/vuex-map-fields
*/
import { getField, updateField } from 'vuex-map-fields';
import { getBreakpoint, urlPrefix } from '@mixins/globals';

const renditionSize = {
  xs: '406.406',
  lg: '651.651',
};
let breakpoint = getBreakpoint();

let parseImage = (state, image) => {
  let src =
    image.CORE_PAGE_TYPE[0] === 'PHOTO'
      ? `${image.CORE_CRX_PATH[0]}.rend.hgtvcom.${state.renditionSize}.jpeg`
      : `${image.CORE_PROMO_IMAGE_URL[0]}.rend.hgtvcom.${state.renditionSize}.jpeg`;
  let original =
    image.CORE_PAGE_TYPE[0] === 'PHOTO'
      ? image.CORE_CRX_PATH[0]
      : image.CORE_PROMO_IMAGE_URL[0];

  let credit = null;
  if (image.PHOTO_CREDIT && image.PHOTO_CREDIT.length) {
    credit = `Credit: ${image.PHOTO_CREDIT[0]}`;
  }

  let coreURL = (image.CORE_URL && image.CORE_URL[0]) || '';

  if (!image.CORE_URL) console.log('Issues:', image, image.src);

  return {
    index: null,
    route: image.route,
    id: image.CORE_ASSETUID[0],
    title: typeof image.CORE_TITLE !== 'undefined' ? image.CORE_TITLE[0] : '',
    url: urlPrefix(coreURL),
    type: image.CORE_PAGE_TYPE[0].toLowerCase(),
    desc:
      typeof image.CORE_SEO_DESCRIPTION !== 'undefined'
        ? image.CORE_SEO_DESCRIPTION[0]
        : '',
    image: src,
    original: original,
    shoppable:
      typeof image.PHOTO_SHOPPABLE !== 'undefined'
        ? image.PHOTO_SHOPPABLE[0] == 'true'
        : false,
    tags: image.CORE_TAG_CLOUD,
    productId:
      typeof image.CORE_WAYFAIR_PRODUCT_ID !== 'undefined'
        ? image.CORE_WAYFAIR_PRODUCT_ID[0]
        : false,
    track: true,
    processed: false,
    credit,
    mdm: null,
  };
};

const state = () => {
  return {
    screen: breakpoint,
    renditionSize: renditionSize[breakpoint],
    images: [],
    imagesSortedByIndex: [],
    displayOrder: [],
    page: [],
    rows: [],
    currentPage: 1,
    imagesByRow: [],
    facets: [],
    numOfResults: 36,
    offset: null,
    searchTerm: '',
    dimensions: null,
    rowIndex: null,
  };
};

const getters = {
  getField,
  imageCount(state) {
    return state.images.length;
  },
  sortedImages(state) {
    if (state.images) {
      return [...state.images].sort((a, b) => {
        if (a.displayIndex < b.displayIndex) {
          return -1;
        }
        if (a.displayIndex > b.displayIndex) {
          return 1;
        }
        if (a.displayIndex === b.displayIndex) {
          return 0;
        }
      });
    }
    return [];
  },
  getPhotoById(state, id) {
    return state.images.filter(image => image.id == id);
  },
  getPhotoByProductId(state, productId) {
    return state.images.filter(image => image.productId == productId);
  },
  getFacetCategory() {
    return function(facet, full = false) {
      if (!facet) return '';

      if (facet === 'CUSTOM_FACET:SHOPPABLE') return 'shop';

      let regex;

      if (full) {
        regex = /(^[\w\d\'\-\"\:\/]+)\#([\w\_]+)(\:.+)$/gi;
      } else {
        regex = /(^[\w\d\'\-\"\:\/]+)\#([a-zA-Z]+)(\w*)\:(.)+$/gi;
      }

      return facet.replace(regex, '$2').toLowerCase();
    };
  },
  getFacetCategoryAndValue() {
    return function(facet) {
      if (!facet) return '';
      const regex = /(^[\w\d\'\-\"\:\/]+)\#([\w\_]+)(\:.+)$/gi;
      return facet.replace(regex, '$2$3').toLowerCase();
    };
  },
};

const mutations = {
  updateField,
  addPage(state, imageSet) {
    if (imageSet && imageSet.length) {
      state.page.push(imageSet);
    }
  },
  addImage(state, image) {
    state.images.push(image);
  },
  setIndex(state, { id, idx }) {
    let img = state.images.find(image => image.id === id);
    if (img) {
      img.index = idx;
    }
  },
  resetImages(state) {
    state.images = [];
  },
  resetRows(state) {
    state.rows = [];
    state.imagesByRow = [];
  },
  addToRows(state, row) {
    state.rows.push(row);
  },
  addToImagesByRow(state, value) {
    if (typeof value !== 'undefined') {
      state.imagesByRow.push(value);
    }
  },
  hasBeenProcessed(state, index) {
    state.images[index].processed = true;
  },
  prependToFacets(state, item) {
    if (!state.facets.includes(item)) state.facets.unshift(item);
  },
  sortImagesByIndex(state) {
    state.imagesSortedByIndex = [...state.images].sort((a, b) => {
      if (a.index === null || b.index === null) {
        return 0;
      }

      if (a.index < b.index) {
        return -1;
      }

      if (a.index > b.index) {
        return 1;
      }

      return 0;
    });
  },
};

const actions = {
  parseSet({ commit, state }, set) {
    let images = set.map(img => {
      img.route = set.route;
      let image = parseImage(state, img);
      commit('addImage', image);
    });
    commit('addPage', images);
  },
  setDisplayIndex({ commit }, items) {
    commit('setIndex', items);
  },
  clearPhotos({ commit }) {
    commit('resetImages');
    commit('resetRows');
  },
  addRow({ commit }, row) {
    commit('addToRows', row);
  },
  addImagesByRow({ commit }, value) {
    commit('addToImagesByRow', value);
  },
  processImage({ commit }, index) {
    commit('hasBeenProcessed', index);
  },
  prependFacet({ commit }, facet) {
    commit('prependToFacets', facet);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
