export default {
  first: function(value) {
    if (!value) return '';
    value = value.toString();
    const regex = /^([0-9a-z\s\'\-\"\/]+)\:.*/gi;
    return value.replace(regex, '$1').toLowerCase();
  },
  last: function(value) {
    if (!value) return '';
    value = value.toString();
    const regex = /^.*\:([0-9a-z\s\'\_\-\"\/]+)$/gi;
    return value.replace(regex, '$1').toLowerCase();
  },
  onlyChild: function(value) {
    if (!value) return '';
    value = value.toString();
    const regex = /^([\w\d\'\_\-\"\/]*)\/([\w\d\'\_\-\"]+)$/gi;
    return value.replace(regex, '$2').toLowerCase();
  },
  pictureDay: function(value) {
    if (!value) return '';
    value = value.toString().toLowerCase();
    const regex = /\_/gi;
    return value
      .replace(regex, ' ')
      .split(' ')
      .map(letter => letter.charAt(0).toUpperCase() + letter.substring(1))
      .join(' ');
  },
};
