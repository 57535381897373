<template>
  <button :class="styles" @click.prevent.stop.capture="handleClick">
    <span ref="droplet" class="droplet" />
    <slot>Facet</slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonFacet',

  props: {
    type: {
      type: String,
      default: 'shop',
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styles() {
      return [
        'button button__facet',
        `button__facet--${this.type}`,
        {
          'is-Active': this.isActive,
          'is-Disabled': this.isDisabled,
        },
      ];
    },
  },

  methods: {
    handleClick(e) {
      this.animateDroplet(e);
      this.$emit('click');

      this.isActive ? this.$emit('toggleOff') : this.$emit('toggleOn');
    },

    animateDroplet(e) {
      const droplet = this.$refs.droplet;

      droplet.classList.remove('drop');

      const d = Math.max(this.$el.offsetWidth, this.$el.offsetHeight);

      droplet.style.width = d + 'px';
      droplet.style.height = d + 'px';

      const x = e.offsetX - d / 2;
      const y = e.offsetY - d / 2;

      droplet.style.left = x + 'px';
      droplet.style.top = y + 'px';

      droplet.classList.add('drop');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles/common.scss';

.button__facet {
  background: #f5f5f5;
  border-radius: 2px;

  text-transform: uppercase;

  font-size: 12px;
  letter-spacing: 0.1em;

  margin: 4px 0;

  &:hover {
    background-color: map-get($facet_colors, Disabled);
  }

  &.is-Active {
    background-color: saturate(
      darken(map-get($facet_colors, Disabled), $active__darken),
      $active__saturation
    );
  }

  &--shop {
    &.has-FiltersSet,
    &:hover {
      background-color: map-get($facet_colors, ShopAccent);
    }
    &.is-Active {
      background-color: saturate(
        darken(map-get($facet_colors, ShopAccent), $active__darken),
        $active__saturation
      );
    }
  }

  &--rooms {
    $color: map-get($facet_colors, RoomAccent);
    &.has-FiltersSet,
    &:hover {
      background-color: $color;
    }

    &.has-FiltersSet {
      &:hover {
        background-color: darken(
          saturate($color, $active__saturation),
          $active__darken
        );
      }
    }

    &.is-Active {
      background-color: saturate(
        darken(map-get($facet_colors, RoomAccent), $active__darken),
        $active__saturation
      );
    }
  }

  &--styles {
    $color: map-get($facet_colors, StyleAccent);

    &.has-FiltersSet,
    &:hover {
      background-color: $color;
    }

    &.has-FiltersSet {
      &:hover {
        background-color: darken(
          saturate($color, $active__saturation),
          $active__darken
        );
      }
    }

    &.is-Active {
      background-color: saturate(
        darken(map-get($facet_colors, StyleAccent), $active__darken),
        $active__saturation
      );
    }
  }

  &--colors {
    $color: map-get($facet_colors, ColorAccent);
    &.has-FiltersSet,
    &:hover {
      background-color: $color;
    }

    &.has-FiltersSet {
      &:hover {
        background-color: darken(
          saturate($color, $active__saturation),
          $active__darken
        );
      }
    }

    &.is-Active {
      background-color: saturate(
        darken(map-get($facet_colors, ColorAccent), $active__darken),
        $active__saturation
      );
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: default;

    color: #e5e5e5;
    background-color: saturate(
      lighten(map-get($facet_colors, Disabled), 8),
      $active__saturation
    );
  }
}

.droplet {
  background: $droplet_color;
  border-radius: 100%;
  transform: scale(0);
  display: block;
  position: absolute;
  z-index: 2;

  &.drop {
    animation: ripple 325ms linear;
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>
