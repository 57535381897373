<template>
  <row
    :is-split="display.isSplit"
    :is-reversed="display.isReversed"
    :has-gallery="display.hasGallery"
    :index="index"
    :route="route"
  >
    <FeedPhoto
      :container-attrs="standardContainerAttrs"
      :image="image"
      :lazyload="lazyload"
      :data-track="image.track"
      :data-mdm="image.mdm"
      :card-image-class="{ [cardImageClass]: true }"
      css-class="c-Feed--photo"
    />
    <div class="c-Feed--group">
      <FeedPhoto
        v-for="photo in imageGroup"
        :key="photo.id"
        :container-attrs="standardContainerAttrs"
        :image="photo"
        :lazyload="lazyload"
        :data-track="photo.track"
        :data-mdm="photo.mdm"
        :card-image-class="{ [cardImageClass]: true }"
        css-class="c-Feed--photo"
      />
    </div>
  </row>
</template>

<script>
import feedRowMixin from '@mixins/feed-row';
import row from '@common/row';
import FeedPhoto from '@common/feed-photo';

export default {
  name: 'Standard',
  components: {
    row,
    FeedPhoto,
  },
  mixins: [feedRowMixin],
  props: {
    rowType: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    display: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    image() {
      return this.images[0];
    },
    imageGroup() {
      return this.images.filter((image, index) => index > 0);
    },
    standardContainerAttrs() {
      let attrs = this.photoContainerAttrs;
      attrs.class[this.cardImageClass] = true;
      attrs.class['c-Feed--item--photo'] = true;
      return attrs;
    },
    route() {
      let img = this.images.find(image => image.route);
      return (img && img.route) || {};
    },
  },
};
</script>
