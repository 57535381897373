<template>
  <div v-if="hasCTA" v-bind="attributes" :data-img-index="imgIndex">
    <a
      :href="targetUrl"
      target="self"
      @click.stop.prevent="openLightbox(image)"
    >
      <div
        :class="{
          ...cardImageClass,
          ...photoWrapperClass,
        }"
      >
        <div class="c-Gallery-cta">{{ cta }}</div>
        <img
          v-bind="$attrs"
          :src="source"
          :data-src="image.image"
          :data-image-id="imageID"
          :alt="alt"
          :class="cssClass"
          :data-shoppable="shoppable"
          :data-product-id="productId"
        />
      </div>
      <p v-if="!hideTitle" class="c-Feed--item-headline">{{ image.title }}</p>
    </a>
  </div>
  <div v-else v-bind="attributes" :data-img-index="imgIndex">
    <a
      :href="targetUrl"
      :target="target"
      @click.stop.prevent="openLightbox(image)"
    >
      <img
        v-bind="$attrs"
        :src="source"
        :data-src="image.image"
        :data-image-id="imageID"
        :alt="alt"
        :class="cssClass"
        :data-shoppable="shoppable"
        :data-product-id="productId"
      />
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FeedPhoto',
  inheritAttrs: false,
  props: {
    containerAttrs: {
      type: Object,
      default: () => ({}),
    },
    image: {
      type: Object,
      default: () => {},
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    openNewTab: {
      type: Boolean,
      default: true,
    },
    cssClass: {
      type: String,
      default: '',
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    cta: {
      type: String,
      default: null,
    },
    cardImageClass: {
      type: Object,
      default: () => {},
    },
    photoWrapperClass: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$store.dispatch('lightbox/addImage', this.image);
  },
  computed: {
    ...mapGetters('lightbox', ['lightboxEnabled']),
    alt() {
      return this.image.title;
    },
    description() {
      return this.image.desc;
    },
    src() {
      return this.image.image;
    },
    target() {
      return this.openNewTab ? '_blank' : '_self';
    },
    imageID() {
      return this.image.id;
    },
    targetUrl() {
      return this.image.url;
    },
    track() {
      return this.image.track;
    },
    hasCTA() {
      return typeof this.cta === 'string';
    },
    hasLightbox() {
      return !this.hasCTA && this.lightboxEnabled;
    },
    source() {
      let src = this.$attrs.src || this.image.image;
      return this.lazyload
        ? "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3C/svg%3E"
        : src;
    },
    shoppable() {
      return this.image.shoppable ? 'true' : 'false';
    },
    productId() {
      return this.image.shoppable ? this.image.productId : null;
    },
    imgIndex() {
      return String(this.image.index);
    },
    attributes() {
      const classObj = {
        ...this.containerAttrs.class,
        'show-img-index': this.showImageIndex,
      };
      return { ...this.containerAttrs, class: classObj };
    },
  },
  methods: {
    ...mapActions('lightbox', ['setActiveImage']),

    openLightbox(image) {
      this.setActiveImage(image);
      if (this.hasLightbox) {
        this.$emit('track-click', image);
      } else {
        if (this.hasWindow) {
          window.open(this.targetUrl, '_self');
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@style/variables/index.scss';

.c-Feed {
  &--row {
    display: flex;
    flex-direction: row;
    position: relative;

    @media (max-width: 1005px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .card-image--4 {
      background-color: #dfcbde;
    }
  } // section

  &--photo,
  &--item img {
    max-height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    transform: scale(1);
    opacity: 1;
    transition: opacity 0.5s ease-in-out, transform 165ms ease-in-out;
  }

  &--item--photo,
  &--photo-wrapper {
    .c-Feed--photo,
    img {
      position: relative;
      max-height: 100%;
      max-width: 100%;
      opacity: 1;
      transition: opacity 500ms ease-in-out, transform $base-timing ease-in-out;

      &[data-src] {
        opacity: 0;
      }
    }
  }

  &--item {
    // a -> img
    position: relative;
    z-index: 1;
    flex-basis: $feed-column-width;
    margin: $feed-gutter-half;

    .photoLibraryLandingPage &,
    .photoLibraryViewerPage & {
      width: $feed-column-width;
      height: $feed-column-width;
    }

    &:hover {
      cursor: zoom-in;

      .c-Feed--photo,
      img {
        z-index: 2;
        transform: scale($base-scaling);
        &-large {
          transform: scale($scaling-large);
        }
      }
    }
  } // div

  &--group {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-basis: ($feed-column-width * 2);
    justify-content: center;

    .has-Gallery & {
      flex-direction: row;
      align-items: center;
    }
  } // div

  a {
    color: inherit;
  }

  &--photo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    object-fit: contain;

    &::after {
      position: absolute;
      z-index: -1;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      content: ' ';
      background-color: #9dc6cc;
      transform: rotate(3deg);
    }
  }

  &--item-headline {
    margin: 1rem auto auto;
    font-size: 21px;
    font-weight: $font-weight-light;
    line-height: 1.35;
  }

  &--gallery {
    cursor: pointer;

    .c-Gallery-cta {
      z-index: 5;
      animation: fade-down ($base-timing) ease-in-out forwards;
    }

    &:hover {
      .c-Gallery-cta {
        animation: fade-up ($base-timing) ease-in-out forwards;
      }
    }
  }
}

.c-Feed--row {
  &.l-Split,
  &.l-Reverse,
  &.has-Gallery {
    .photoLibraryLandingPage &,
    .photoLibraryViewerPage & {
      height: 670px;
    }

    .c-Feed {
      .photoLibraryLandingPage &,
      .photoLibraryViewerPage & {
        &--item {
          width: 635px;
          height: 635px;
        }
        &--item,
        &--item-photo {
          flex-basis: ($feed-column-width * 2) + $feed-gutter;
        }
      }
      &--group {
        flex-basis: $feed-column-width;
        flex-wrap: wrap;

        .c-Feed--item {
          flex-basis: $feed-column-width;
          .photoLibraryLandingPage &,
          .photoLibraryViewerPage & {
            width: 300px;
            height: 300px;
          }
          .singleImagePage & {
            flex-basis: 340px;
          }
        }
      }
      &--item,
      &--item-photo {
        flex-basis: ($feed-column-width * 2) + $feed-gutter;
      }
    }
  }

  &.l-Reverse {
    flex-direction: row-reverse;
  }
}

.c-Gallery-cta {
  position: absolute;
  z-index: 2;
  display: inline-block;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  font-weight: $font-weight-regular;

  @supports (backdrop-filter: blur()) {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(4px);
  }

  transform: translateY(10px);
  opacity: 0;
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-down {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(5px);
  }
}
</style>
