export default {
  setExperiments(state, payload) {
    state.experiments = payload;
  },
  setExperimentIsSuccess(state, { id, isSuccess }) {
    const experiment = state.experiments.find(exp => exp.id === id);
    if (experiment) {
      experiment.isSuccess = isSuccess;
    }
  },
};
